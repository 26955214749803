<template>
  <div>
    <Title
      :pages="[
        { icon: 'desktop', page: $t('Monitoreo') },
        { icon: '', page: page_title.toUpperCase() + (ruta != null ? ruta.numTrip : '') },
      ]"
    ></Title>

    <v-container v-if="ruta != null" fluid>
      <div class="col-md-12 mt-5">
        <div class="row">
          <div class="col-md-12 p-10">
            <div class="box-monitoring-mother">
              <div class="row">
                <div class="col-md-2 box-monitoring divider">
                  <h3 style="margin-top: 3px;">{{ $t("Ruta") }}</h3>
                  <h3 style="margin-top:0px">#{{ ruta.numTrip }}</h3>
                </div>
                <div class="col-md-2 box-monitoring divider">
                  <p class="mb-0">
                    <b>CONTENEDOR</b>
                  </p>
                  <span style="text-transform: uppercase;">{{ ruta.numContainer }}</span>
                </div>
                <div class="col box-monitoring divider">
                  <p class="mb-0">
                    <b>RECOLECCION</b>
                  </p>
                  <span style="text-transform: uppercase;">{{ ruta.originPort != null ? ruta.originPort.dirOrigen : "-" }}</span>
                </div>
                <div class="col box-monitoring divider">
                  <p class="mb-0">
                    <b>DESCONSOLIDADO</b>
                  </p>
                  <span style="text-transform: uppercase;">{{ ruta.warehouse != null ? ruta.warehouse.nameWarehouse : "-" }}</span>
                </div>
                <div class="col box-monitoring ">
                  <p class="mb-0">
                    <b>ENTREGA</b>
                  </p>
                  <span style="text-transform: uppercase;">{{ ruta.destinationPort != null ? ruta.destinationPort.dirOrigen : "-" }}</span>
                </div>
              </div>
            </div>
            <div class="info_extra">
              <span class="item-blue mr-2" @click="menu = !menu">Mas info</span>
              <font-awesome-icon icon="chevron-down" class="cursor pt-1" style="color:#3483fa" @click="menu = true" v-if="!menu" />
              <font-awesome-icon icon="chevron-up" class="cursor pt-1" style="color:#3483fa" @click="menu = false" v-else />
            </div>
            <v-expand-transition>
              <div v-show="menu" class="extra-info-monitoreo complete">
                <div class="row pl-3 pr-3">
                  <div class="col-md-2 subBoxChofer"></div>
                  <div class="col-md-2 subBoxChofer">
                    <p style="color:grey;font-size:12px;height:18px">
                      RECOLECCION
                    </p>
                    <p class="mb-0">
                      <b>Chofer</b>
                    </p>
                    <span>{{ ruta.driverPickup != null ? ruta.driverPickup.nameDriver : "-" }}</span>
                  </div>
                  <div class="col-md-2 subBoxChofer">
                    <p style="height:18px"></p>
                    <p class="mb-0">
                      <b>Vehiculo</b>
                    </p>
                    <p class="mb-0">{{ ruta.vehiclePickup != null ? ruta.vehiclePickup.patent : "-" }}</p>
                    <p class="mb-0">{{ ruta.vehiclePickup != null ? ruta.vehiclePickup.companyTransport.nameTransport : "-" }}</p>
                  </div>

                  <div class="col-md-2 subBoxChofer">
                    <p style="color:grey;font-size:12px;height:18px">
                      ENTREGA
                    </p>
                    <p class="mb-0">
                      <b>Chofer</b>
                    </p>
                    <span>{{ ruta.driverDelivery != null ? ruta.driverDelivery.nameDriver : "-" }}</span>
                  </div>
                  <div class="col-md-2 subBoxChofer">
                    <p style="height:18px"></p>
                    <p class="mb-0">
                      <b>Vehiculo</b>
                    </p>
                    <p class="mb-0">{{ ruta.vehicleDelivery != null ? ruta.vehicleDelivery.patent : "-" }}</p>
                    <p class="mb-0">{{ ruta.vehicleDelivery != null ? ruta.vehicleDelivery.companyTransport.nameTransport : "-" }}</p>
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col p-10 pr-0 ">
            <div v-if="ruta != null" id="rutaMonitoreo">
              <div v-for="stage in ruta.stages" :key="stage.order">
                <Stage :stage="stage" />
              </div>
            </div>
          </div>

          <div class="col p-10 pl-0 ">
            <div id="mapa">
              <GeoMonitoringContainer v-if="ruta != null" :ruta="ruta" />
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import GeoMonitoringContainer from "./GeoMonitoringContainer.vue";
import Stage from "./Stage.vue";
import { rutasContainerService } from "@/libs/ws/rutasContainerService";

export default {
  components: { Title, Stage, GeoMonitoringContainer },
  data() {
    return {
      page_title: this.$t("Ruta") + " #",
      menu: false,
      series01: [],
      driver: [],
      chart02Options: {},
      chart01Options: {
        stroke: { show: false },
        colors: ["#f23d4f", "#d4d4d4"],
        legend: { show: false },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  offsetY: -20,
                },
                value: {
                  fontSize: 16,
                  offsetY: -10,
                  color: "#f23d4f",
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "",
                  formatter: function(w) {
                    var result = 0;
                    if (w.globals.series[0] + w.globals.series[1] == 0) {
                      result = 0;
                    } else {
                      result = Math.trunc((w.globals.series[0] * 100) / (w.globals.series[0] + w.globals.series[1]));
                    }
                    return result + "%";
                  },
                },
              },
            },
          },
        },
      },
      ruta: null,
    };
  },
  mounted() {
    this.loadRuta(this.$route.params.rutaid);
    this.updateEvery();
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  },
  watch: {
    rutaObject() {
      this.series01 = [parseInt(this.getDelivery()) + parseInt(this.ruta.stages.length)];
    },
  },
  computed: {
    rutaObject() {
      return this.ruta;
    },
  },
  methods: {
    updateEvery() {
      this.interval = setInterval(
        function() {
          this.loadRuta(this.$route.params.rutaid);
        }.bind(this),
        60000
      );
    },
    loadRuta(ruta) {
      rutasContainerService
        .getRuta(ruta)
        .then((response) => {
          this.ruta = response;
          var aux = Object.assign({}, this.chart01Options);
          aux.colors = ["#d4d4d4", "#d4d4d4"];
          aux.plotOptions.pie.donut.labels.value.color = "#d4d4d4";
          this.chart02Options = Object.assign({}, aux);
          this.series01 = [parseInt(this.getDelivery()) + parseInt(this.ruta.stages.length)];
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    getDelivery() {
      var contador = 0;
      this.ruta.stages.forEach(function(elemento) {
        if (elemento.delivery != null) {
          contador++;
        }
      });
      return contador;
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
  },
};
</script>
