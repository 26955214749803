import { genericService } from "./genericService";
import { akeronService } from "./akeronService";

const section = "KPI";

function CantidadRutas(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.quantity_routes;
    });
}

function CantidadRemitos(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.quantity_deliverynotes;
    });
}

function EmpresasTransporte(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.companies_transport;
    });
}

function RemitosCliente(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.deliverynotes_by_client;
    });
}

function CantidadTiposTransporte(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.quantity_transport;
    });
}

function TiposTransporte(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.types_transport;
    });
}

function ChoferesTotal(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.quantity_driver;
    });
  /*  const choferes_total = { quantity: 10 };
  return new Promise((resolve) => {
    resolve(choferes_total);
  }); */
}
function ChoferesDetail(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.drivers_detail;
    });
}

function datosDeCarga(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.datos_carga;
    });
}

function CantidadChoferes(param) {
  return akeronService
    .make_post_request("Dashboard/GetInformation", {
      fromDate: param.fromDate,
      toDate: param.toDate,
    })
    .then((response) => {
      return response.drivers_quantity_trip;
    });
}

export const kpiService = {
  CantidadRutas,
  CantidadRemitos,
  EmpresasTransporte,
  RemitosCliente,
  CantidadTiposTransporte,
  TiposTransporte,
  ChoferesTotal,
  ChoferesDetail,
  datosDeCarga,
  CantidadChoferes,
};
