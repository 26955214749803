<template>
  <div style="margin-bottom:25px">
    <Title :pages="[{ icon: 'sticky-note', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + $t('Pedido')"
        modal_title="Pedido"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadPageCustom(service)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-btn @click="dialog = true" color="primary" v-if="create_access">
        {{ $t("Crear") + " " + $t("Pedido") }}
      </v-btn>

      <v-dialog v-model="dialog_import" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" outlined class="ml-2" v-bind="attrs" v-on="on" v-if="create_access">
            Importar Pedidos
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5 lighten-2" style="background-color:#4caf50">
            Importar Pedidos
          </v-card-title>
          <v-card-text class="pb-2 pl-4">
            <h6 class="mt-3">{{ $t("Seleccione_archivo") }}</h6>
            <v-combobox
              v-model="import_type"
              data-vv-as="Tipo de importación"
              name="import_type"
              item-text="name"
              item-value="id"
              :items="import_types"
              :label="$t('Import_type')"
              autocomplete="off"
              v-validate="'required'"
            ></v-combobox>
            <span class="red--text">{{ errors.first("import_type") }}</span>

            <v-file-input class="pt-0" :label="$t('Archivo_excel')" truncate-length="10" v-model="excelFile" @change="loadExcel"></v-file-input>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="(dialog_import = false), (excelFile = '')">
              {{ $t("Cerrar") }}
            </v-btn>
            <v-btn color="success" text @click="createMassive()">
              {{ $t("Crear_masivamente") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--Filter-->
      <div class="row mt-2 mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="pedido_filter.length > 0"
                      v-model="dateInicio_filter"
                      :label="$t('Fecha_desde')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateInicio_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-4">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field :disabled="pedido_filter.length > 0" v-model="dateFin_filter" :label="$t('Fecha_hasta')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="dateFin_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>

              <div class="col-md-4">
                <v-combobox v-model="cliente_filter" name="client" item-text="nameClient" item-value="id" :items="clients" :label="$t('Cliente')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
            </div>
            <!-- fin row1 -->

            <div class="row">
              <div class="col-md-4">
                <v-text-field data-vv-as="pedido" :label="$t('Pedido')" name="pedido" v-model="pedido_filter"></v-text-field>
              </div>
              <div class="col-md-4">
                <v-combobox v-model="state_filter" name="state" item-text="name" item-value="id" :items="states" :label="$t('Estado')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col-md-3">
                <v-checkbox label="Con remito" v-model="con_remito_filter"></v-checkbox>
              </div>

              <div class="col-md-1 d-flex align-items-right justify-content-end pt-8">
                <v-btn color="primary" dark @click="loadPageCustom(service, 0)">{{ $t("Buscar") }}</v-btn>
              </div>

              <!-- fin row2 -->
            </div>
          </div>
        </div>
      </div>

      <v-card v-if="list_access">
        <div class="row">
          <div class="col-md-12 p-4">
            <v-card-title>
              <span class="text-uppercase">{{ page_title }}</span>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
            </v-card-title>
            <hr class="mb-0" />
            <v-data-table :headers="headers" :items="list" :loading="loading" :loading-text="$t('cargando_espere')" hide-default-footer disable-pagination>
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.pedido }}</td>
                  <td>{{ props.item.remito }}</td>
                  <td>{{ props.item.client.nameClient }}</td>
                  <td>
                    {{ props.item.subClient != null ? props.item.subClient.nameSubClient : " " }}
                  </td>
                  <td>
                    {{ props.item.subClient != null ? props.item.subClient.subClientAddress : " " }}
                  </td>

                  <td>
                    {{ props.item.pallets }}
                  </td>
                  <td>
                    {{ props.item.packages }}
                  </td>
                  <td>
                    {{ props.item.cubicMeters }}
                  </td>
                  <td>
                    {{ props.item.kg }}
                  </td>

                  <td>
                    {{ new Intl.NumberFormat("es-EN").format(props.item.declaredValue) }}
                  </td>

                  <td>
                    {{ props.item.rack }}
                  </td>

                  <td>
                    {{ props.item.bobina }}
                  </td>

                  <td>
                    {{ props.item.tambores }}
                  </td>

                  <td>${{ new Intl.NumberFormat("es-EN").format(props.item.pv) }}</td>

                  <td class="text-center px-0" style="min-width: 150px">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                          edit
                        </v-icon>
                      </template>
                      <span>{{ $t("Editar") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)">
                          remove_red_eye
                        </v-icon>
                      </template>
                      <span>{{ $t("Ver") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="red" class="mr-1" @click="deleteItem(props.item)" v-if="delete_access">
                          delete
                        </v-icon>
                      </template>
                      <span>{{ $t("Eliminar") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning"> {{ $t("sin_resultados") + search }}". </v-alert>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>

      <div v-if="cant_pages > 1" class="text-center pt-3">
        <v-pagination
          v-if="list_access"
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPageCustom(service)"
          @previous="loadPageCustom(service)"
          @input="loadPageCustom(service)"
        ></v-pagination>
      </div>
    </v-container>
    <div>
      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteConfirm" />

      <v-dialog v-model="dialog_pedidos_no_ingresados" width="500">
        <v-card>
          <v-card-title class="headline">
            {{ $t("Error") }}
          </v-card-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_ingresados") + cant_pedidos_ingresados }}
          </v-list-item-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_sin_ingresar") }}
          </v-list-item-title>
          <v-card-text class="pb-0" style="padding-top:0px !important ; height:30px" v-for="(item, i) in pedidos_no_ingresados" :key="i">
            <v-list-item>
              <v-list-item-title> • {{ item.numPedido }}</v-list-item-title>
            </v-list-item>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialog_pedidos_no_ingresados = false">
              {{ $t("Cerrar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import readXlsxFile from "read-excel-file";

import { clientService } from "@/libs/ws/clientService";
import { subClientService } from "@/libs/ws/subClientService";
import { pedidosService } from "@/libs/ws/pedidosService";

export default {
  components: {
    Title,
    DialogDelete,
    Modal,
  },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      page_title: "Pedidos",
      service: pedidosService,
      clients: [],
      subclients: [],
      clusters: [],
      states: [],
      localidades: [],
      provincias: [],
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      cliente_filter: null,
      con_remito_filter: false,
      state_filter: "",
      state_edit: "",
      pedido_filter: "",
      onlyPendientes_filter: false,
      selected: [],
      mainItem: {
        id: null,
        IdClient: "",
        IdSubClient: "",
        historial: [],
        Pedido: "",
        Remito: "",
        SubClients: "",
        Clients: "",
        declaredValue: 0,
        pallets: 0,
        packages: 0,
        kg: 0,
        cubicMeters: 0,
        images: "",
        seleccionado: false,
        rack: 0,
        bobina: 0,
        tambores: 0,
      },
      defaultItem: null,
      headers: [
        {
          text: "N°" + this.$t("Pedido"),
          align: "left",
          sortable: true,
          value: "Pedido",
          class: "lighten-2",
        },
        {
          text: "N°" + this.$t("Remito"),
          align: "left",
          sortable: true,
          value: "Remito",
          class: "lighten-2",
        },
        {
          text: this.$t("Cliente"),
          align: "left",
          sortable: true,
          value: "clients.nameClient",
          class: "lighten-2",
        },
        {
          text: "Sub" + this.$t("Cliente"),
          align: "left",
          sortable: true,
          value: "subclients.nameSubClient",
          class: "lighten-2",
        },
        {
          text: this.$t("Direccion"),
          align: "left",
          sortable: true,
          value: "subclients.subClientAddress",
          class: "lighten-2",
        },
        {
          text: this.$t("Pallets"),
          align: "left",
          sortable: true,
          value: "pallets",
          class: "lighten-2",
        },
        {
          text: this.$t("Bultos"),
          align: "left",
          sortable: true,
          value: "packages",
          class: "lighten-2",
        },
        {
          text: "M3",
          align: "left",
          sortable: true,
          value: "cubicMeters",
          class: "lighten-2",
        },
        {
          text: "KG",
          align: "left",
          sortable: true,
          value: "kg",
          class: "lighten-2",
        },
        {
          text: this.$t("Valor_declarado"),
          align: "left",
          sortable: true,
          value: "declaredValue",
          class: "lighten-2",
        },
        {
          text: this.$t("Rack"),
          align: "left",
          sortable: true,
          value: "rack",
          class: "lighten-2",
        },
        {
          text: this.$t("Bobina"),
          align: "left",
          sortable: true,
          value: "bobina",
          class: "lighten-2",
        },
        {
          text: this.$t("Tambores"),
          align: "left",
          sortable: true,
          value: "tambores",
          class: "lighten-2",
        },
        {
          text: "VR",
          align: "left",
          sortable: true,
          value: "pv",
          class: "lighten-2",
        },
        {
          text: this.$t("Accion"),
          align: "center",
          sortable: true,
          value: "enable",
          class: "lighten-2",
        },
      ],
      import_types: [
        { id: 0, name: "Importacion basica" },
        { id: 1, name: "Importacion completa" },
      ],
      excelFile: null,
      import_type: null,
      dialog_import: false,
      dialog_pedidos_no_ingresados: false,
      pedidos_no_ingresados: [],
      cant_pedidos_ingresados: 0,
      importObject: {
        NameClient: "",
        ExternalCodSubClient: "",
        NameSubClient: "",
        CUITSubClient: "",
        AddressSubClient: "",
        CPSubClient: "",
        LocalitySubClient: "",
        ProvinceSubClient: "",
        CountrySubClient: "",
        NumRemito: "",
        DeclaredValue: "",
        Weight: "",
        CubicMeters: "",
        Estate: "",
        Pallet: "",
        Package: "",
        Observation: "",
      },
    };
  },
  mounted() {
    this.loadClients();
    this.loadSubclients();
    this.loadPageCustom(this.service);
    this.defaultItem = Object.assign({}, this.mainItem);
  },
  computed: {},
  watch: {},
  methods: {
    createMassive() {
      if (this.import_type == null) {
        return;
      }

      var params = Object.assign({}, this.importObject);
      this.pedidos_upload = [];
      var validarDatosRta = {};
      var flag = false;

      //import type 0 BASICA
      if (this.import_type != null && this.import_type.id == 0) {
        this.excelupload.forEach((element, index) => {
          if (!this.rowEmpty(element)) {
            validarDatosRta = this.validarDatos(element, index);
            if (validarDatosRta.value) {
              params.NameClient = element[0];
              params.ExternalCodSubClient = element[1] != null ? element[1].toString() : "";
              params.NameSubClient = element[2] != null ? element[2].toString() : "";
              params.NumRemito = element[3] != null ? element[3].toString() : "";
              params.DeclaredValue = element[4] != null ? parseFloat(element[4]) : 0;
              params.Weight = element[5] != null ? parseFloat(element[5]) : 0;
              params.CubicMeters = element[6] != null ? parseFloat(element[6]) : 0;
              params.Pallet = element[7] != null ? parseFloat(element[7]) : 0;
              params.Package = element[8] != null ? parseFloat(element[8]) : 0;
              params.Rack = element[9] != null ? parseFloat(element[9]) : 0;
              params.Bobina = element[10] != null ? parseFloat(element[10]) : 0;
              params.Tambores = element[11] != null ? parseFloat(element[11]) : 0;
              params.Observation = element[12] != null ? element[12].toString() : "";
              params.Estate = element[13] != null ? element[13].toString() : "";
              this.pedidos_upload.push(params);
              params = Object.assign({}, this.importObject);
            } else {
              this.$toast.error(validarDatosRta.msg);
              flag = true;
            }
          }
        });
      } else {
        //import type 1 COMPLETA

        this.excelupload.forEach((element, index) => {
          if (!this.rowEmpty(element)) {
            validarDatosRta = this.validarDatos(element, index);
            if (validarDatosRta.value) {
              params.NameClient = element[0] != null ? element[0].toString() : "";
              params.NameSubClient = element[1] != null ? element[1].toString() : "";
              params.CountrySubClient = element[2] != null ? element[2].toString() : "";

              params.ProvinceSubClient = element[3] != null ? element[3].toString() : "";
              params.LocalitySubClient = element[4] != null ? element[4].toString() : "";
              params.AddressSubClient = element[5] != null ? element[5].toString() : "";
              params.NumRemito = element[6] != null ? element[6].toString() : "";
              params.DeclaredValue = element[7] != null ? parseFloat(element[7]) : 0;
              params.Weight = element[8] != null ? parseFloat(element[8]) : 0;
              params.CubicMeters = element[9] != null ? parseFloat(element[9]) : 0;
              params.Pallet = element[10] != null ? parseFloat(element[10]) : 0;
              params.Package = element[11] != null ? parseFloat(element[11]) : 0;
              params.Rack = element[12] != null ? parseFloat(element[12]) : 0;
              params.Bobina = element[13] != null ? parseFloat(element[13]) : 0;
              params.Tambores = element[14] != null ? parseFloat(element[14]) : 0;
              params.Observation = element[15] != null ? element[15].toString() : "";
              params.Estate = element[16] != null ? element[16].toString() : "";

              this.pedidos_upload.push(params);
              params = Object.assign({}, this.importObject);
            } else {
              this.$toast.error(validarDatosRta.msg);
              flag = true;
            }
          }
        });
      }

      if (!flag) {
        this.dialog_import = false;
        var pedidosobjeto = { Remitos: this.pedidos_upload };

        this.service
          .createMassiveInBag(pedidosobjeto, this.import_type.id)
          .then((response) => {
            if (response.ingresados > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.loadPageCustom(this.service);
              this.excelFile = "";
              this.cant_pedidos_ingresados = response.ingresados;
            } else {
              this.$toast.error(this.$t("Ocurrio_error"), "Error");
            }
            if (response.noIngresados != 0) {
              this.pedidos_no_ingresados = response.remitosNoIngresados;
              this.dialog_pedidos_no_ingresados = true;
            }
          })
          .catch((response) => {
            this.$toast.error(this.$t("Ocurrio_error"), "Error");
          });
      }
    },
    loadExcel() {
      let xlsxfile = this.excelFile;
      readXlsxFile(xlsxfile).then((rows) => {
        rows.splice(0, 1);
        this.excelupload = rows;
      });
    },
    rowEmpty(element) {
      return (
        element[0] == null &&
        element[1] == null &&
        element[2] == null &&
        element[3] == null &&
        element[4] == null &&
        element[5] == null &&
        element[6] == null &&
        element[7] == null &&
        element[8] == null &&
        element[9] == null &&
        element[10] == null &&
        element[11] == null &&
        element[12] == null &&
        element[13] == null &&
        element[14] == null &&
        element[15] == null
      );
    },
    validarDatos(element, index) {
      var object = { value: true, msg: "" };

      if (this.import_type.id == 0) {
        if (element[0] == null) {
          return {
            value: false,
            msg: "Error, El campo cliente es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[1] == null && element[2] == null) {
          return {
            value: false,
            msg: "Error, El campo sub cliente external code o name sub cliente es obligatorio fila " + (index + 1),
          };
        }
      } else {
        if (element[0] == null) {
          return {
            value: false,
            msg: "Error, El campo cliente es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[1] == null) {
          return {
            value: false,
            msg: "Error, El campo Subcliente es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[2] == null) {
          return {
            value: false,
            msg: "Error, El campo Pais es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[3] == null) {
          return {
            value: false,
            msg: "Error, El campo Provincia es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[4] == null) {
          return {
            value: false,
            msg: "Error, El campo Localidad es obligatorio en la fila " + (index + 1),
          };
        }
        if (element[5] == null) {
          return {
            value: false,
            msg: "Error, El campo Direccion es obligatorio en la fila " + (index + 1),
          };
        }
      }

      return object;
    },
    deleteConfirm() {
      this.delete(this.service);
    },
    subcli: (item) => (item.id == 0 ? "" : item.nameSubClient + " (" + item.subClientAddress + ")"),
    loadPageCustom(service, startIndex = (this.current_page - 1) * this.$store.state.config.items_per_page) {
      this.loadListCustom(service, startIndex);
    },
    loadListCustom(service, startIndex = 0) {
      this.loading = true;
      var params = {
        fecha_desde: this.formatDate(this.dateInicio_filter),
        fecha_hasta: this.formatDate(this.dateFin_filter),
        client_id: this.cliente_filter != null ? this.cliente_filter.id : 0,
        NumPedido: this.pedido_filter,
        Estado: this.state_filter != "" ? this.state_filter.id : 0,
        ConRemito: this.con_remito_filter,
      };
      service
        .filter(params, startIndex, this.$store.state.config.items_per_page)
        .then((response) => {
          if (startIndex == 0) {
            this.current_page = 1;
          }
          if (response.list != null) {
            this.list = response.list;
          } else {
            this.list = [];
          }
          this.cant_pages = Math.ceil(response.quantity / this.$store.state.config.items_per_page);
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadSubclients() {
      this.loading_subclientes = true;
      subClientService
        .filter(this.search == "" ? " " : this.search, 0, 100, 1)
        .then((response) => {
          this.subclients = response.list;
          this.loading_subclientes = false;
        })
        .catch((e) => {
          console.log("service.load error");
          this.loading_subclientes = false;
          console.log(e);
        });
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    provincia_qty: (item) => (item.id == 0 ? "" : item.name + " (" + item.quantity + ")"),
    localidad_qty: (item) => (item.id == 0 ? "" : item.name + " (" + item.quantity + ")"),
    getLocalidadesFilters() {
      var arrayLocalidades = [];
      if (this.localidad_filter.length != 0) {
        this.localidad_filter.forEach((element) => {
          arrayLocalidades.push(element.id);
        });
      }
      return arrayLocalidades;
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getState(estado) {
      return ""; //pedidosService.getStatesHTML(estado);
    },
  },
};
</script>
