<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col-md-6">
                <v-text-field v-model="item.Orden" label="Orden" outlined disabled></v-text-field>
              </div>
              <div class="col-md-6">
                <v-text-field v-model="item.remito" label="Nro de remito" name="nroRemito" data-vv-as="Numero de Remito" autocomplete="off" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("nroRemito") }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <v-combobox
                  v-model="item.client"
                  data-vv-as="Cliente"
                  name="cliente"
                  item-text="nameClient"
                  item-value="id"
                  :items="clients"
                  label="Cliente"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("cliente") }}</span>
              </div>
              <div class="col-md-6">
                <v-combobox
                  v-model="item.subClient"
                  data-vv-as="Sub cliente"
                  name="subcliente"
                  :item-text="subcli"
                  item-value="id"
                  :items="subclients"
                  label="Sub Cliente"
                  autocomplete="off"
                  :disabled="isViewModal()"
                  :hide-no-data="!search"
                  :search-input.sync="search"
                  @keyup="loadSubclients()"
                  :loading="loading_subclientes"
                >
                </v-combobox
                ><span class="red--text">{{ errors.first("subcliente") }}</span>
              </div>
            </div>

            <div class="row justify-content-around">
              <div class="col-md-3">
                <v-text-field v-model="item.pallets" type="number" min="0" label="Pallets" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.packages" type="number" min="0" label="Bultos" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.kg" type="number" min="0" label="KG" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.cubicMeters" type="number" min="0" label="M3" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.declaredValue" label="Valor declarado" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.rack" type="number" min="0" label="Rack" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.bobina" type="number" min="0" label="Bobina" :disabled="isViewModal()"></v-text-field>
              </div>
              <div class="col-md-3">
                <v-text-field v-model="item.tambores" type="number" min="0" label="Tambores" :disabled="isViewModal()"></v-text-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <v-text-field v-model="item.observation" label="Observaciones" :disabled="isViewModal()"></v-text-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 ">
                <h6 class="centerText">Adicionales</h6>
              </div>
            </div>
            <div class="row  d-flex justify-content-around">
              <div class="d-flex justify-content-center" v-for="adicional in this.item.additionals" :key="adicional.tipo">
                <v-checkbox v-model="adicional.checkbox" :label="$t(adicional.tipo)" :disabled="isViewModal()"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text v-if="formBtn != '' && formBtn != 'Crear'" type="submit">{{ formBtn }}</v-btn>
            <v-menu v-if="formBtn != 'Editar' && formBtn != ''" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-1" color="success" dark v-bind="attrs" v-on="on" @click="createItem(0)">
                  Guardar
                </v-btn>

                <v-btn class="ml-1" color="success" dark v-bind="attrs" v-on="on" @click="createItem(2)">
                  Siguiente remito mismos datos
                </v-btn>
                <v-btn class="ml-1" color="success" dark v-bind="attrs" v-on="on" @click="createItem(1)">
                  Siguiente remito
                </v-btn>
              </template>
            </v-menu>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { clientService } from "@/libs/ws/clientService";
import { subClientService } from "@/libs/ws/subClientService";
import { remitosService } from "@/libs/ws/remitosService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
    itemRuta: Object,
  },
  data() {
    return {
      show: false,
      clients: [],
      subclients: [],
      loading_subclientes: false,
      search: " ",
      adicionales: [{ tipo: "Assistant" }, { tipo: "Turn" }],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      this.loadAdicionales();
      if (this.dialog) {
        this.getOrdenNumber();
        this.loadAdicionales();
        this.setCheckbox();
      }
    },
  },
  mounted() {
    this.loadClients();
    this.loadSubclients();
  },

  methods: {
    setCheckbox() {
      if (this.item.id > 0) {
        this.item.additionals.forEach((element) => {
          if (element.checkbox == false) {
            element.checkbox = false;
          } else {
            element.checkbox = true;
          }
        });
        this.itemRuta.aditionals.forEach((element) => {
          var found = this.item.additionals.findIndex((element2) => element.tipo == element2.tipo);
          if (found == -1) {
            element.checkbox = false;
            this.item.additionals.push(element);
          }
        });
        this.item.additionals.sort(function(a, b) {
          if (a.tipo > b.tipo) {
            return 1;
          }
          if (a.tipo < b.tipo) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    },
    setAdditionals() {
      let adicionales = [];
      let adicional = { tipo: "" };
      let adicional_defult = { tipo: "" };
      this.item.additionals.forEach((element) => {
        if (element.checkbox == true) {
          adicional.tipo = element.tipo;
          adicionales.push(adicional);
        }
        adicional = Object.assign({}, adicional_defult);
      });

      adicionales.sort(function(a, b) {
        if (a.tipo > b.tipo) {
          return 1;
        }
        if (a.tipo < b.tipo) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.item.additionals = adicionales;
      console.log(this.item.additionals);
    },
    loadAdicionales() {
      if (!(this.item.id > 0)) {
        this.item.additionals = this.itemRuta.aditionals;
        this.item.additionals.sort(function(a, b) {
          if (a.tipo > b.tipo) {
            return 1;
          }
          if (a.tipo < b.tipo) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    },
    subcli: (item) => (item.id == 0 ? "" : item.nameSubClient + " (" + item.subClientAddress + ")"),
    getOrdenNumber() {
      this.item.Orden = this.itemRuta.delivered.length + 1;
    },

    isViewModal() {
      return this.formBtn == "";
    },
    loadSubclients() {
      this.loading_subclientes = true;
      subClientService
        .filter(this.search == "" ? " " : this.search, 0, 100, 1)
        .then((response) => {
          this.subclients = response.list;
          this.loading_subclientes = false;
        })
        .catch((e) => {
          console.log("service.load error");
          this.loading_subclientes = false;
          console.log(e);
        });
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem(tipo = 0) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          //e.preventDefault();
          if (this.item.id > 0) {
            this.setAdditionals();
            this.item.IdTrip = this.item.idTrip;
            remitosService
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success("Se actualizó correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.setAdditionals();
            this.item.IdTrip = this.itemRuta.id;
            remitosService
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success("Se creo correctamente.", "OK");
                  this.$emit("updateDialog", false, tipo);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
