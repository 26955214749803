<template>
  <v-dialog v-if="stage != null" v-model="dialog_images" max-width="750">
    <v-card>
      <v-card-title class="headline">Imagenes</v-card-title>

      <v-card-text>
        <p class="mt-5 mb-5 fs-16">Tarea '{{ stage.description }}'</p>

        <div class="row">
          <div class="col-md-4" v-for="(item, index) in stage.image" :key="index">
            <a :href="item.image" target="_blank">
              <img :src="item.image" style="height: 150px;width: 100%;object-fit:cover" />
            </a>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="$emit('close')">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog_images: Boolean,
    stage: Object,
    close: Function,
  },
  data: () => ({}),
  methods: {},
};
</script>
