<template>
  <div>
    <!--
    <v-btn class="w-100 mb-8 btn rounded-0" v-on:click="filter">
      Filter
    </v-btn>
    <v-btn class="w-100 mb-8 btn rounded-0" v-on:click="create">
      Create
    </v-btn>
    <v-btn class="w-100 mb-8 btn rounded-0" v-on:click="remove">
      Delete
    </v-btn>
    <v-btn class="w-100 mb-8 btn rounded-0" v-on:click="update">
      Update
    </v-btn>
    -->
  </div>
</template>

<script>
// @ is an alias to /src
import { zoneService } from "@/libs/ws/zoneService";
export default {
  name: "Home",
  components: {},
  methods: {
    filter() {
      zoneService.filter("", 0, 10).then((response) => {});
    },
    create() {
      zoneService.create("Zona Test3").then((response) => {});
    },
    remove() {
      zoneService.remove(1025).then((response) => {});
    },
    update() {
      zoneService.update(1025, "Zona Test2").then((response) => {});
    },
  },
};
</script>
