import { genericService } from "./genericService";

const section = "Module";

function getAll() {
  return genericService.get("Module/ListAllModule");
}

export const moduleService = {
  getAll,
};
