import { genericService } from "./genericService";
import { akeronService } from "./akeronService";

const section = "MonitoreoContainerTrip";

function getAll() {
  return genericService.get("MonitoreoContainerTrip/MonitoreoHeaderTrip");
}

function filter(params) {
  const param = {
    Fecha: params.Fecha,
    NumTrip: params.numTrip,
    numContenedor: params.numContenedor,
    externalCode: params.externalCode,
  };

  return akeronService.make_post_request("MonitoreoContainerTrip/MonitoreoHeaderTrip", param).then((response) => {
    return response;
  });
}

function getMonitoreoDrivers(params) {
  const param = {
    Fecha: params.Fecha,
    NumTrip: params.numTrip,
    numRemito: params.numRemito,
    IdClient: params.clientId,
  };

  return akeronService.make_post_request("MonitoreoContainerTrip/MonitoreoDrivers", param).then((response) => {
    return response;
  });
}

export const monitoreoContainerService = {
  getAll,
  filter,
  getMonitoreoDrivers,
};
