<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre') + '*'" name="nameCluster" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>
              </div>
              <div class="col-md-12">
                <v-combobox
                  v-model="item.country"
                  data-vv-as="Paises"
                  name="contries"
                  item-text="name"
                  item-value="id"
                  :items="paises"
                  label="País"
                  autocomplete="off"
                  v-validate="'required'"
                  @change="loadProvincias(item.country.id)"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("origen") }}</span>
              </div>
              <div class="col-md-12">
                <v-combobox
                  v-model="item.province"
                  data-vv-as="Provincias"
                  name="provinces"
                  item-text="name"
                  item-value="id"
                  :items="provincias"
                  label="Provincias"
                  autocomplete="off"
                  v-validate="'required'"
                  @change="loadLocality(item.province.id)"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("origen") }}</span>
              </div>
              <div class="col-md-12">
                <v-combobox
                  multiple
                  v-model="item.localities"
                  data-vv-as="Localidades"
                  name="localities"
                  item-text="name"
                  item-value="id"
                  :items="localidades"
                  label="Localidades"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("origen") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalMixin from "@/mixins/ModalMixin.vue";
import { clusterService } from "@/libs/ws/clusterService";
import { localityService } from "@/libs/ws/localityService";
import { provinceService } from "@/libs/ws/provinceService";
import { countryService } from "@/libs/ws/countryService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  mixins: [ModalMixin],
  data() {
    return {
      localidades: [],
      provincias: [],
      paises: [],
      country: "",
      province: "",
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadPaises();
  },
  methods: {
    createItem() {
      if(this.loading)
        return;
      this.loading = true;
      
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.item.localities = this.setLocalities();
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success("Se actualizó correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.item.localities = this.setLocalities();
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success("Se creo correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
    loadPaises() {
      countryService
        .getAll()
        .then((response) => {
          this.paises = response.countries;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadProvincias(id) {
      provinceService
        .getAllProvince(id)
        .then((response) => {
          this.provincias = response.province;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadLocality(id) {
      localityService
        .getAll(id)
        .then((response) => {
          this.localidades = response.locality;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    setLocalities() {
      let localidades = [];
      this.item.localities.forEach((element) => {
        localidades.push(element.id);
      });
      return localidades;
    },
    isViewModal() {
      return this.formBtn == "";
    },
  },
};
</script>
