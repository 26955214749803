<template>
  <div>
    <div class="row shipping-monitoring ">
      <div class="col-md-10">
        <div>
          <div :class="getClass(assigned.estate.id)">
            {{ assigned.orden }}
          </div>
          <b class="fs-15">{{ $t("Remito") }} # {{ assigned.remito }}</b>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="blue" class="ml-2" @click="infoRemito(assigned)">
                image
              </v-icon>
            </template>
            <span>{{ $t("Ver_imagen") }}</span>
          </v-tooltip>
        </div>
        <span>{{ assigned.estate.name }}</span>
      </div>

      <div class="col-md-2">
        <div class="float-right" style="padding-top:10px">
          <font-awesome-icon v-if="!show_detail" icon="chevron-down" @click="show_detail = !show_detail" class="cursor" />
          <font-awesome-icon v-else icon="chevron-up" @click="show_detail = !show_detail" class="cursor" />
        </div>
      </div>
    </div>

    <div v-if="show_detail" class="shipping-monitoring-info ">
      <div class="row">
        <div class="col-md-3">
          <v-input :messages="[assigned.client.nameClient]">
            {{ $t("Cliente") }}
          </v-input>
        </div>
        <div class="col-md-5">
          <v-input :messages="[assigned.subClient.nameSubClient]"> Sub {{ $t("Cliente") }} </v-input>
        </div>
        <div class="col-md-4">
          <v-input :messages="[assigned.subClient.subClientAddress]">
            {{ $t("Direccion") }}
          </v-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <v-input :messages="[assigned.pallets.toString()]">
            {{ $t("Pallets") }}
          </v-input>
        </div>
        <div class="col-md-3">
          <v-input :messages="[assigned.packages.toString()]">
            {{ $t("Bultos") }}
          </v-input>
        </div>
        <div class="col-md-3">
          <v-input :messages="[assigned.kg.toString()]">
            Kg
          </v-input>
        </div>
        <div class="col-md-3">
          <v-input :messages="[assigned.cubicMeters.toString()]"> M<sup>3</sup> </v-input>
        </div>
      </div>
      <div class="row" v-if="assigned.observation != ''">
        <div class="col-md-3">
          <v-input :messages="[assigned.observation]"> Observaciones </v-input>
        </div>
      </div>
    </div>

    <InfoRemito v-if="mainItemRemito != null" button_name="Informacion remito" modal_title="Informacion remito" :item="mainItemRemito" :dialog="dialogInfo" @updateDialog="updateDialogInfo" />
  </div>
</template>

<script>
import InfoRemito from "@/views/Remitos/InfoRemito.vue";
export default {
  name: "ShippingMonitoring",
  components: { InfoRemito },
  props: {
    assigned: Object,
    ruta: Object,
  },
  data() {
    return {
      show_detail: false,
      dialogInfo: false,
      mainItemRemito: null,
    };
  },
  mounted() {},
  updated() {},
  methods: {
    infoRemito(item) {
      this.errors.clear();
      this.mainItemRemito = Object.assign({}, item);
      this.dialogInfo = true;
    },
    updateDialogInfo(value) {
      if (!value) {
        this.dialogInfo = false;
        return;
      }
    },
    getClass(estado) {
      switch (estado) {
        case 1:
          return "circle-monitoring bg-autorizado";
        case 2:
          return "circle-monitoring bg-retenido";
        case 3:
          return "circle-monitoring bg-no-entregado";
        case 4:
          return "circle-monitoring bg-entregado";
        case 5:
          return "circle-monitoring bg-despachado";
        case 6:
          return "circle-monitoring bg-en-camino";
        default:
          return "circle-monitoring bg-en-camino";
      }
    },
  },
};
</script>
