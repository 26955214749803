import { genericService } from "./genericService";
import { akeronService } from "./akeronService";
import { isEmpty } from "ramda";

const section = "DeliveredNotesWeb";

const AUTORIZADO = 1;
const RETENIDO = 2;
const NO_ENTREGADO = 3;
const ENTREGADO = 4;
const DESPACHADO = 5;
const EN_CAMINO = 6;
const DESCARTADO = 7;
const EN_PREPARACION = 8;
const ARMADO = 9;
const PRIORIDAD = 10;
const RETIRADO = 11;

function remove(id) {
  return genericService.removeV2(section + "/DeleteDeliveredNotes", id);
}

function create(item) {
  const param = {
    IdTrip: item.IdTrip, //item.IdTrip
    Orden: item.Orden,
    IdClient: item.client.id,
    IdSubClient: item.subClient == "" ? 0 : item.subClient.id,
    Remito: item.remito,
    Observation: item.observation,
    DeclaredValue: parseInt(item.declaredValue),
    Pallets: parseFloat(item.pallets),
    Packages: parseFloat(item.packages),
    Kg: parseFloat(item.kg),
    CubicMeters: parseFloat(item.cubicMeters),
    Additional: item.additionals,
    Rack: parseFloat(item.rack),
    Bobina: parseFloat(item.bobina),
    Tambores: parseFloat(item.tambores),
  };

  return genericService.createV2(section + "/CreateDeliveredNotesNew", param);
}

function update(item) {
  const param = {
    Id: item.id,
    IdTrip: item.IdTrip, //item.IdTrip
    Orden: item.orden,
    IdClient: item.client.id,
    IdSubClient: item.subClient.id,
    Remito: item.remito,
    Observation: item.observation,
    DeclaredValue: parseInt(item.declaredValue),
    Pallets: parseFloat(item.pallets),
    Packages: parseFloat(item.packages),
    Kg: parseFloat(item.kg),
    CubicMeters: parseFloat(item.cubicMeters),
    Additional: item.additionals != null ? item.additionals : [],
    Estate: item.estate.name,
    Rack: parseFloat(item.rack),
    Bobina: parseFloat(item.bobina),
    Tambores: parseFloat(item.tambores),
  };
  return genericService.updateV2(section + "/UpdateDeliveredNotesNew", param);
}

function cambiarOrden(id, orden) {
  const param = {
    Id: id,
    Type: orden,
  };
  return genericService.updateV2(section + "/UpdateOrdenDeliveredNotes", param);
}

function getPDF(id) {
  return genericService.get(section + "/CreatePDF/" + id);
}

function createInBag(item) {
  const param = {
    IdClient: item.client.id,
    IdSubClient: item.subClient.id,
    Remito: item.remito,
    Observation: item.observation,
    declaredValue: parseInt(item.declaredValue),
    Pallets: parseFloat(item.pallets),
    Packages: parseFloat(item.packages),
    Kg: parseFloat(item.kg),
    CubicMeters: parseFloat(item.cubicMeters),
    codEstate: item.estate != null ? item.estate.id : null,
    Rack: parseFloat(item.rack),
    Bobina: parseFloat(item.bobina),
    Tambores: parseFloat(item.tambores),
  };
  return genericService.createV2(section + "/CreateDeliveredNotesInBag", param);
}

function createMassiveInBag(item, type) {
  if (type == 0) {
    return genericService.createV2(section + "/CreateDeliveredNotesInBagMasive", item);
  } else {
    //type == 1
    return genericService.createV2(section + "/CreateDeliveredNotesInBagMasiveV2", item);
  }
}

function filter(item) {
  const param = {
    NumRemito: item.remito_id,
    IdClient: item.client_id,
    IdSubClient: item.IdSubClient,
    Localidades: item.Localidad,
    Estado: item.Estado,
    IdCluster: item.IdCluster,
  };
  return (
    akeronService
      /* .make_post_request("DeliveredNotesWeb/ListDeliveredNotesInBag", param) */
      .make_post_request("DeliveredNotesWeb/ListCustomerDeliveredNotesNew", param)
      .then((response) => {
        let result = {
          quantity: response.quantity,
          list: response["remitos"],
        };
        return result;
      })
  );
}

function filterRuteo(item) {
  const param = {
    NumRemito: item.remito_id,
    IdClient: item.client_id,
    IdSubClient: item.IdSubClient,
    Localidades: item.Localidad,
    Estado: item.Estado,
    IdCluster: item.IdCluster,
  };
  return akeronService.make_post_request("DeliveredNotesWeb/ListDeliveredNotesInBagRuteo", param).then((response) => {
    let result = {
      quantity: response.quantity,
      list: response["remitos"],
      provincias: response["provincias"],
      localidades: response["localidades"],
    };
    return result;
  });
}

function descartarRemitos(param) {
  return akeronService
    .make_post_request("DeliveredNotesWeb/DiscardDelivered", {
      Remitos: param.remitos,
      Estado: DESCARTADO,
    })
    .then((response) => {
      return response;
    });
}

function entregarRemito(param) {
  return akeronService
    .make_post_request("DeliveredNotesWeb/CloseDeliveredNote", {
      IdTrip: param.ruta,
      IdDelivered: param.remito,
      DeliveredString: param.date,
      Images: param.images,
      IdEstado: param.state.id,
      Observation: param.observation,
    })
    .then((response) => {
      return response;
    });
}

function filterCustomer(param, from = 0, limit = 10) {
  return akeronService
    .make_post_request("DeliveredNotesWeb/ListCustomerDeliveredNotesNewHistorial", {
      jtStartIndex: from,
      jtPageSize: limit,
      FechaDesde: param.fecha_desde,
      FechaHasta: param.fecha_hasta,
      IdCliente: param.client_id,
      IdEstado: param.Estado,
      NumRemito: param.NumRemito,
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["remitos"],
      };
      return result;
    });
}

function addImages(item) {
  const param = {
    Id: item.ruta,
    Image: item.images,
  };
  return akeronService.make_post_request(section + "/AddImageDeliveredNotes", param).then((response) => {
    return response;
  });
}

function removeImage(item) {
  const param = {
    IdImage: item.image_id,
    IdDelivered: item.remito_id,
  };
  return akeronService.make_post_request(section + "/DeleteImageDeliveredNotes", param).then((response) => {
    return response;
  });
}

function AsignInBagToExistingtRoute(item) {
  const param = {
    IdTrip: item.ruta_id,
    Remitos: item.remitos,
  };
  return akeronService.make_post_request(section + "/AsignDeliveredNotes", param).then((response) => {
    let result = {};
    return result;
  });
}

function GetStates() {
  return genericService.get("DeliveredNoteState/ListAll");
}

function GetFilterStates() {
  return genericService.get("DeliveredNoteState/ListRuteo");
}

function ChangeState(param) {
  return akeronService.make_post_request("DeliveredNotesWeb/ChangeState", param).then((response) => {
    return response;
  });
}

function getStatesHTML(estado) {
  switch (estado) {
    case AUTORIZADO:
      return '<p class="state_autorizado">AUTORIZADO</p>';
    case RETENIDO:
      return '<p class="state_retenido">RETENIDO</p>';
    case NO_ENTREGADO:
      return '<p class="state_no_entregado">NO ENTREGADO</p>';
    case ENTREGADO:
      return '<p class="state_entregado">ENTREGADO</p>';
    case DESPACHADO:
      return '<p class="state_despachado">EN CARGA</p>';
    case EN_CAMINO:
      return '<p class="state_en_camino">EN CAMINO</p>';
    case DESCARTADO:
      return '<p class="state_retenido">DESCARTADO</p>';
    case EN_PREPARACION:
      return '<p class="state_en_preparacion">EN PREPARACION</p>';
    case ARMADO:
      return '<p class="state_armado">ARMADO</p>';
    case PRIORIDAD:
      return '<p class="state_prioridad">PRIORIDAD</p>';
    case RETIRADO:
      return '<p class="state_retirado">RETIRADO</p>';
  }
}

function DesasociateDelivered(param) {
  return akeronService.make_post_request("DeliveredNotesWeb/Desasociate", param).then((response) => {
    return response;
  });
}

export const remitosService = {
  getPDF,
  cambiarOrden,
  remove,
  create,
  update,
  createInBag,
  filter,
  createMassiveInBag,
  AsignInBagToExistingtRoute,
  GetStates,
  filterCustomer,
  ChangeState,
  getStatesHTML,
  DesasociateDelivered,
  filterRuteo,
  GetFilterStates,
  descartarRemitos,
  entregarRemito,
  removeImage,
  addImages,
  AUTORIZADO,
  RETIRADO,
  RETENIDO,
  NO_ENTREGADO,
  ENTREGADO,
  DESPACHADO,
  EN_CAMINO,
  DESCARTADO,
  EN_PREPARACION,
  ARMADO,
  PRIORIDAD,
};
