<template>
  <div>
    <Title :pages="[{ icon: 'crosshairs', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        :item="mainItem"
        @loadList="loadClusters()"
      />
      <!--Filter-->
      <div class="row mb-3">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre')" name="name" v-model="nombre_filter"></v-text-field>
              </div>

              <div class="col-md-4 d-flex align-items-center justify-content-end pr-10">
                <v-btn color="primary" dark @click="loadClusters()">{{ $t("Buscar") }}</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card>
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" :label="$t('Buscar')" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" :loading-text="$t('cargando_espere')" hide-default-footer disable-pagination>
          <template v-slot:item="props">
            <tr>
              <td>
                {{ props.item.id }}
              </td>
              <td>{{ props.item.name }}</td>
              <td>
                <div class="row">
                  <div v-for="item in props.item.localities" :key="item.id" class="ml-1">
                    <v-chip>{{ item.name }}</v-chip>
                  </div>
                </div>
              </td>
              <td class="justify-content-center layout px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="editItem(props.item)">
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-1" @click="deleteItem(props.item)">
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("Eliminar") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ $t("Sin_resultados") + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
    </v-container>
  </div>
</template>

<script>
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { clusterService } from "@/libs/ws/clusterService";
import Modal from "./Modal.vue";

export default {
  components: { Title, DialogDelete, Modal },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      page_title: "Cluster",
      modal_title: "Cluster",
      mainItem: {
        id: "",
        name: "",
        localities: [],
      },
      defaultItem: null,
      headers: [
        { text: this.$t("Numero"), value: "number" },
        {
          text: this.$t("Nombre"),
          align: "left",
          sortable: true,
          value: "nameDriver",
        },
        {
          text: this.$t("Localidades"),
          align: "left",
          sortable: true,
          value: "cuit",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
      nombre_filter: "",
      service: clusterService,
      dialog_delete: false,
    };
  },
  mounted() {
    this.loadClusters();
  },
  methods: {
    loadClusters() {
      this.loading = false;
      if (this.nombre_filter == "") {
        this.nombre_filter = " ";
      }
      clusterService
        .list(this.nombre_filter)
        .then((response) => {
          this.list = response.clusters;
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loading = true;
      setTimeout(() => {
        this.loadClusters();
      }, 1000);
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
