import { akeronService } from './akeronService'
import axios from "axios";

function login(username,password) {
  const param = {
    "Username": username,
    "Password" : password
  }
  //return akeronService.make_post_request_no_auth('login/LoginWeb',param)  
  return axios.post(process.env.VUE_APP_API_URL+'login/LoginWeb',param).then((response) => {
    if (response.status == 200) {
      return response.data;
    }
    return {
      status: false,
      msg:
        "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
        response.status,
      code: response.status,
    };
  })
}

function isAuthenticated(){
  return (
    localStorage.getItem('token') != undefined &&
    localStorage.getItem('expires') != undefined &&
    localStorage.getItem('username') != undefined &&    
    Date.now() < Date.parse(localStorage.getItem('expires'))
  )
}

function logOut(){    
  localStorage.removeItem('token') 
  localStorage.removeItem('expires') 
  localStorage.removeItem('username')  
  localStorage.removeItem("rol");
  localStorage.removeItem("external");
}

export const authService = {
  login,
  isAuthenticated,
  logOut
}
