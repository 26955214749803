<template>
  <v-dialog v-if="item != null" v-model="dialog" @click:outside="$emit('close', item)" max-width="500">
    <v-card>
      <v-card-title class="headline">Modificar estado</v-card-title>

      <v-card-text>
        <p class="mt-5 mb-5" style="color:black; font-size:18px">
          Estas por modificar el estado del remito <strong>#{{ item.remito }}</strong>
        </p>

        <div class="row">
          <div class="col-md-12">
            <v-combobox v-model="item.state" data-vv-as="Estado" name="Estado" item-text="name" item-value="id" :items="states" label="Estado" autocomplete="off" v-validate="'required'"></v-combobox>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="$emit('close', item)">
          Cancelar
        </v-btn>

        <v-btn color="green darken-1" :loading="loading" text @click="validate">
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { remitosService } from "@/libs/ws/remitosService";
export default {
  props: {
    dialog: Boolean,
    loading: Boolean,
    item: Object,
    action: Function,
    close: Function,
  },
  data() {
    return {
      states: [
        { id: remitosService.ENTREGADO, name: "ENTREGADO" },
        { id: remitosService.NO_ENTREGADO, name: "NO ENTREGADO" },
      ],
    };
  },
  watch: {},
  mounted() {},
  methods: {
    validate() {
      if (this.item.state == null) {
        this.$toast.error("Debe seleccionar un estado", "Error");
        return;
      }

      this.$emit("action", this.item);
    },
  },
};
</script>
