<template>
  <div>
    <Title
      :pages="[{ icon: 'chart-bar', page: page_title.toUpperCase() }]"
    ></Title>

    <v-container fluid>
      <div
        v-if="list_access"
        style="min-height:630px ;background-color:rgba(221, 225, 230,1);border: solid 1px #ccc;"
      >
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <div style="padding:20px">
          <div class="row">
            <div class="col-md-12 d-flex justify-content-end my-0 py-0 ">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    style="max-width:250px; max-height:40px"
                    outlined
                    dense
                    v-model="dates"
                    label="Desde - Hasta"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" range no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates), searchData()"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <v-card height="220px">
                <v-card-text style="height:100%">
                  <div class="d-flex justify-content-center kpi-data">
                    <div class="kpi-p">
                      <p>
                        {{ $t("Rutas") }}
                      </p>
                      <b style="font-size:45pt">{{ cantidadRutas }}</b>
                    </div>
                    <div style="width:90% ; margin-top:10px">
                      <hr style="border:solid 1px #d6d1d1 ; width:100%" />
                    </div>
                    <div class="kpi-p">
                      <p>
                        {{ $t("Remitos") }}
                      </p>
                      <b style="font-size:45pt">{{ remitos_total }}</b>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-md-6">
              <v-card height="220px">
                <v-card-title
                  class="justify-content-center pb-0"
                  style="height:25%"
                >
                  <p>
                    {{ $t("Remitos") }}
                  </p>
                </v-card-title>

                <v-card-text style="height:75%">
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-6 pt-0">
                      <apexchart
                        ref="Chart01"
                        style="display: flex; justify-content: center"
                        type="pie"
                        width="100%"
                        height="190px"
                        :options="chart01Options"
                        :series="series01"
                      />
                    </div>
                    <div class="col-md-6 p-12">
                      <div style="position:absolute; top:20%; right:20px">
                        <table
                          v-if="!loading"
                          class="table table-m mb-0 table-noborder table-kpi"
                          style="max-height:120px"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <div class="circle_entregado"></div>
                              </td>
                              <td>{{ $t("Entregados") }}</td>

                              <td class="text-right">
                                <b> {{ remitos_entregados }} </b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="circle_no_entregado"></div>
                              </td>
                              <td>{{ $t("No_entregados") }}</td>
                              <td class="text-right">
                                <b>
                                  {{ remitos_no_entregados }}
                                </b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="circle_en_camino"></div>
                              </td>
                              <td>{{ $t("No_entregados_problemas") }}</td>
                              <td class="text-right">
                                <b>
                                  {{ remitos_no_entregados_problemas }}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-md-3">
              <v-card height="220px">
                <v-card-text style="height:100%">
                  <div
                    class="d-flex  flex-row justify-content-center kpi-data"
                    style="margin-top: 20px;"
                  >
                    <p>
                      Datos de carga
                    </p>
                    <div v-if="!loading" class="col-md-7 fs-datos-carga">
                      <h4>
                        BULTOS
                      </h4>
                      <h4>
                        PALLETS
                      </h4>
                      <h4>
                        KG
                      </h4>
                      <h4>
                        M3
                      </h4>
                    </div>
                    <div v-if="!loading" class="col-md-5 fs-datos-carga">
                      <h4>
                        {{
                          datos_carga.bultos == null ||
                          datos_carga.bultos == undefined
                            ? 0
                            : datos_carga.bultos
                        }}
                      </h4>
                      <h4>
                        {{
                          datos_carga.pallets == null ||
                          datos_carga.pallets == undefined
                            ? 0
                            : datos_carga.pallets
                        }}
                      </h4>
                      <h4>
                        {{
                          datos_carga.kg == null || datos_carga.kg == undefined
                            ? 0
                            : datos_carga.kg
                        }}
                      </h4>
                      <h4>
                        <!-- {{
                          datos_carga.m3 == null || datos_carga.m3 == undefined
                            ? 0
                            : datos_carga.m3
                        }} -->
                      </h4>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <v-card height="220px">
                <v-card-title class="justify-content-center pb-0">
                  <p>
                    Empresas de transporte
                  </p>
                </v-card-title>

                <v-card-text>
                  <div id="chart">
                    <apexchart
                      ref="chart02"
                      type="donut"
                      width="100%"
                      height="150px"
                      :options="chart02Options"
                      :series="series02"
                    ></apexchart>
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="col-md-6">
              <v-card height="220px">
                <v-card-title class="justify-content-center pb-0">
                  <p>
                    Remitos por cliente
                  </p>
                </v-card-title>

                <v-card-text>
                  <div class="row d-flex justify-content-center">
                    <div class="col p-12" style="height:200px">
                      <div style="text-align:center">
                        <b style="color:black"> {{ this.$t("Entregados") }}</b>
                      </div>
                      <apexchart
                        ref="Chart03"
                        style="margin: 0 auto;display: flex; justify-content: center"
                        type="pie"
                        width="80%"
                        height="150px"
                        :options="chart03Options"
                        :series="series03"
                      />
                    </div>

                    <div class="col p-12" style="height:200px">
                      <div style="text-align:center">
                        <b style="color:black">
                          {{ this.$t("No_entregados") }}</b
                        >
                      </div>
                      <apexchart
                        ref="Chart04"
                        style="margin: 0 auto;display: flex; justify-content: center;"
                        type="pie"
                        width="80%"
                        height="150px"
                        :options="chart04Options"
                        :series="series04"
                      />
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <v-card height="220px">
                <v-card-title class="justify-content-center pb-0">
                  <p>
                    Tipos de transporte
                  </p>
                </v-card-title>

                <v-card-text>
                  <div class="row d-flex justify-content-center">
                    <div class="col p-12" style="height:200px">
                      <apexchart
                        ref="Chart05"
                        type="bar"
                        height="150"
                        width="100%"
                        :options="chart05Options"
                        :series="series05"
                      ></apexchart>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <div class="col-md-5">
              <v-card height="220px">
                <v-card-title class="justify-content-center pb-0">
                  <p>
                    Choferes
                  </p>
                </v-card-title>

                <v-card-text>
                  <div class="row d-flex justify-content-center">
                    <div class="col p-12">
                      <div id="chart">
                        <apexchart
                          ref="chart06"
                          type="donut"
                          width="100%"
                          height="150px"
                          :options="chart06Options"
                          :series="series06"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div class="col-md-3">
              <v-card height="220px">
                <v-card-title class="justify-content-center pb-0">
                  <p>
                    Viajes por chofer
                  </p>
                </v-card-title>

                <v-card-text>
                  <div class="row d-flex justify-content-center">
                    <div class="col p-12" style="height:200px">
                      <apexchart
                        ref="Chart07"
                        type="bar"
                        height="150"
                        width="100%"
                        :options="chart07Options"
                        :series="series07"
                      ></apexchart>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { kpiService } from "@/libs/ws/kpiService.js";

export default {
  components: { Title },
  mixins: [RolMixin],
  data() {
    return {
      menu: false,
      loading: true,
      dates: [
        new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
      ],
      page_title: "KPI",
      chart01Options: {
        stroke: { show: false },
        colors: ["#5fba7d", "#fff159", "#7390fc"],
        legend: { show: false },
        dataLabels: { enabled: false },
        labels: [
          this.$t("Entregados"),
          this.$t("No_entregados"),
          this.$t("No_entregados_problemas"),
        ],
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "70%",
              labels: { show: false },
            },
          },
        },
      },
      series01: [
        this.remitos_entregados,
        this.remitos_no_entregados,
        this.remitos_no_entregados_problemas,
      ],
      chart02Options: {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                name: {
                  show: true,
                  fontSize: "32px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: 10,
                  formatter: function(val) {
                    return "10";
                  },
                },
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: "50",
                  fontSize: "32px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                },
                value: {
                  show: false,
                  fontSize: "32px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: -5,
                  formatter: function(val) {
                    return parseFloat(val + 2);
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          height: "130",
          offsetY: -15,
          show: true,
        },
      },
      series02: [],
      chart03Options: {
        stroke: { show: false },
        legend: { show: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "70%",
              labels: { show: false },
            },
          },
        },
      },
      series03: [],
      chart04Options: {
        stroke: { show: false },
        legend: { show: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "70%",
              labels: { show: false },
            },
          },
        },
      },
      series04: [],
      chart05Options: {
        chart: {
          type: "bar",
          height: 150,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {},
      },
      series05: [{ data: [] }],
      test: "15",
      chart06Options: {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                name: {
                  show: true,
                  fontSize: "32px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                  color: undefined,
                  offsetY: 10,
                  formatter: function(val) {
                    return val;
                  },
                },
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: " ",
                  fontSize: "32px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 600,
                },
                value: {
                  show: false,
                  fontSize: "32px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: -5,
                  formatter: function(val) {
                    return parseFloat(val + 2);
                  },
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          height: "130",
          offsetY: -15,
          show: true,
        },
      },
      series06: [],
      chart07Options: {
        chart: {
          type: "bar",
          height: 150,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {},
      },
      series07: [{ name: "Cantidad choferes", data: [] }],
      datos_carga: { bultos: null, pallets: null, kg: null, m3: null },
      cantidadRutas: 0,
      remitos_total: 0,
      remitos_no_entregados_problemas: 0,
      remitos_no_entregados: 0,
      remitos_entregados: 0,
      transportes_total: 0,
      cantidad_choferes: [],
      empresas: [],
      choferes: [],
      remitos: [],
      tipos_transporte: [],
      labelsEmpresas: [],
      seriesEmpresas: [],
      labelsRemitos: [],
      seriesRemitosEntregados: [],
      seriesRemitosNoEntregados: [],
      seriesTiposTransporte: [],
      labelsTiposTransporte: [],
      seriesCantidadChoferes: [],
      labelsCantidadChoferes: [],
      seriesChoferes: [],
      labelsChoferes: [],
      choferes_total: 0,
      chart06: null,
      chart02: null,
    };
  },
  watch: {
    /*   dates() {
      if (this.dates.length == 2) {

      }
    }, */

    remitosArray() {
      this.series01 = [
        this.remitos_entregados,
        this.remitos_no_entregados,
        this.remitos_no_entregados_problemas,
      ];
    },
    empresasArray() {
      this.series02 = this.seriesEmpresas;
      this.chart02Options.labels = this.labelsEmpresas;
      var value2 = this.transportes_total;
      var formateo2 = function(val) {
        return value2;
      };
      this.chart02Options.plotOptions.pie.donut.labels.name.formatter = formateo2;
      this.$refs.chart02.updateOptions(this.chart02Options);
    },
    transportes_total() {
      var value2 = this.transportes_total;
      var formateo2 = function(val) {
        return value2;
      };
      this.chart02Options.plotOptions.pie.donut.labels.name.formatter = formateo2;
      this.$refs.chart02.updateOptions(this.chart02Options);
    },
    remitosClienteArray() {
      this.series03 = this.seriesRemitosEntregados;
      this.chart03Options = {
        labels: this.labelsRemitos,
      };
      this.series04 = this.seriesRemitosNoEntregados;
      this.chart04Options = {
        labels: this.labelsRemitos,
      };
    },
    tiposTransporteArray() {
      /*  this.series05 = this.seriesTiposTransporte; */
      this.chart05Options = {
        labels: this.labelsTiposTransporte,
      };
    },
    choferes_total() {
      var value = this.choferes_total;
      var formateo = function(val) {
        return value;
      };
      this.chart06Options.plotOptions.pie.donut.labels.name.formatter = formateo;
      this.$refs.chart06.updateOptions(this.chart06Options);
    },
    choferesArray() {
      this.series06 = this.seriesChoferes;
      this.chart06Options.labels = this.labelsChoferes;
      var value = this.choferes_total;
      var formateo = function(val) {
        return value;
      };
      this.chart06Options.plotOptions.pie.donut.labels.name.formatter = formateo;
      this.$refs.chart06.updateOptions(this.chart06Options);
    },
    cantidadChoferesArray() {
      this.chart07Options = {
        labels: this.labelsCantidadChoferes,
      };
    },
  },
  computed: {
    remitosArray() {
      return this.remitos_total;
    },
    empresasArray() {
      return this.empresas;
    },
    remitosClienteArray() {
      return this.remitos;
    },
    tiposTransporteArray() {
      return this.tipos_transporte;
    },
    choferesArray() {
      return this.choferes;
    },
    cantidadChoferesArray() {
      return this.cantidad_choferes;
    },
  },
  mounted() {
    this.getCantidadRutas();
    this.getChoferesTotal();
    this.getCantidadTransporte();
    this.getCantidadRemitos();
    this.getEmpresasTransporte();
    this.getRemitosPorCliente();
    this.getTiposTransporte();
    this.getChoferesDetail();
    this.getDatosDeCarga();
    this.getCantidadChoferes();
  },
  methods: {
    clearData() {
      this.seriesEmpresas = [];
      this.seriesRemitosEntregados = [];
      this.seriesRemitosNoEntregados = [];
      this.seriesTiposTransporte = [];
      this.seriesChoferes = [];
      this.seriesCantidadChoferes = [];
      this.labelsEmpresas = [];
      this.labelsRemitos = [];
      this.labelsTiposTransporte = [];
      this.labelsCantidadChoferes = [];
      this.labelsChoferes = [];
      this.loading = true;
    },

    getCantidadRutas() {
      console.log(this.dates);
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .CantidadRutas(params)
        .then((response) => {
          this.cantidadRutas = response.quantity;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    getCantidadRemitos() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .CantidadRemitos(params)
        .then((response) => {
          this.remitos_total = response.total;
          this.remitos_entregados = response.entregados;
          this.remitos_no_entregados = response.no_entregados;
          this.remitos_no_entregados_problemas =
            response.no_entregados_problemas;
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getEmpresasTransporte() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .EmpresasTransporte(params)
        .then((response) => {
          this.empresas = response;
          this.setChartDataEmpresas(this.empresas);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    setChartDataEmpresas(empresas) {
      empresas.forEach((element) => {
        if (element.cantidad >= 0) {
          this.seriesEmpresas.push(element.cantidad);
          this.labelsEmpresas.push(element.nombre);
        }
      });
    },
    getRemitosPorCliente() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .RemitosCliente(params)
        .then((response) => {
          this.remitos = response;
          this.setChartDataRemitos(this.remitos);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    setChartDataRemitos(remitos) {
      remitos.forEach((element) => {
        this.seriesRemitosEntregados.push(element.entregados);
        this.seriesRemitosNoEntregados.push(element.no_entregados);
        this.labelsRemitos.push(element.nombre);
      });
    },
    getCantidadTransporte() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .CantidadTiposTransporte(params)
        .then((response) => {
          console.log(response.quantity);
          this.transportes_total = response.quantity;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    getTiposTransporte() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .TiposTransporte(params)
        .then((response) => {
          this.tipos_transporte = response;
          this.setChartDataTransportes(this.tipos_transporte);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    setChartDataTransportes(tipos_transporte) {
      tipos_transporte.forEach((element) => {
        this.seriesTiposTransporte.push(element.cantidad);
        this.labelsTiposTransporte.push(element.nombre);
      });
      this.series05[0].data = this.seriesTiposTransporte;
      this.$refs.Chart05.updateSeries(this.series05, false, true);
    },
    getChoferesTotal() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .ChoferesTotal(params)
        .then((response) => {
          this.choferes_total = response.quantity;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    getChoferesDetail() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .ChoferesDetail(params)
        .then((response) => {
          this.choferes = response;
          this.setChartDataChoferes(this.choferes);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    setChartDataChoferes(choferes) {
      choferes.forEach((element) => {
        this.seriesChoferes.push(element.cantidad);
        this.labelsChoferes.push(element.nombre);
      });
    },
    getDatosDeCarga() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .datosDeCarga(params)
        .then((response) => {
          this.datos_carga = response;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    getCantidadChoferes() {
      var params = { fromDate: this.dates[0], toDate: this.dates[1] };
      kpiService
        .CantidadChoferes(params)
        .then((response) => {
          console.log("choferes");
          console.log(response);

          this.cantidad_choferes = response;
          this.setChartDataCantidadChoferes(this.cantidad_choferes);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    setChartDataCantidadChoferes(choferes) {
      choferes.forEach((element) => {
        this.seriesCantidadChoferes.push(element.cant_choferes);
        this.labelsCantidadChoferes.push("C. viajes " + element.cant_viajes);
      });
      this.series07[0].data = this.seriesCantidadChoferes;
      this.$refs.Chart07.updateSeries(this.series07, false, true);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    searchData() {
      this.clearData();
      this.getCantidadRutas();
      this.getChoferesTotal();
      this.getCantidadRemitos();
      this.getEmpresasTransporte();
      this.getRemitosPorCliente();
      this.getTiposTransporte();
      this.getChoferesDetail();
      this.getCantidadTransporte();
      this.getCantidadChoferes();
      this.getDatosDeCarga();
    },
  },
};
</script>
