import { genericService } from "./genericService";

const section = "SubClient";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(
    section,
    "subClients",
    name,
    from,
    limit,
    active
  );
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    contact: item.contact,
    IdLocality: item.locality.id,
    cuit: item.cuit,
    nameSubClient: item.nameSubClient,
    SubClientAddress: item.subClientAddress,
    SubClientEmail: item.subClientEmail,
    SubClientTelephone: item.subClientTelephone,
    ExternalCode: item.externalCode,
    isActive: item.isActive,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    contact: item.contact,
    IdLocality: item.locality.id,
    cuit: item.cuit,
    nameSubClient: item.nameSubClient,
    SubClientAddress: item.subClientAddress,
    SubClientEmail: item.subClientEmail,
    SubClientTelephone: item.subClientTelephone,
    ExternalCode: item.externalCode,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const subClientService = {
  filter,
  remove,
  create,
  update,
};
