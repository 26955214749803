<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1050px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-4">
                <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
                <v-combobox
                  v-model="item.vehicleType"
                  data-vv-as="Tipo de vehiculo"
                  name="idVehicleType"
                  item-text="type"
                  item-value="id"
                  :items="tipoDeVehiculos"
                  :label="'Tipo de ' + $t('Vehiculo')"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("idVehicleType") }}</span>
                <v-combobox
                  v-model="item.chargeType"
                  data-vv-as="Tipo de carga"
                  name="idChargeType"
                  item-text="typeCharge"
                  item-value="id"
                  :items="tipoDeCagas"
                  label="Tipo de carga"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("idChargeType") }}</span>
                <v-combobox
                  v-model="item.zone"
                  data-vv-as="zona"
                  name="idZone"
                  item-text="zone"
                  item-value="id"
                  :items="zonas"
                  label="Zona"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("idZone") }}</span>
                <v-checkbox label="Activo" v-model="item.isActive" :disabled="isViewModal()"></v-checkbox>
              </div>
              <div class="col-md-4">
                <v-text-field
                  data-vv-as="Costo basico"
                  :label="$t('Costo') + 'básico*'"
                  name="costo_basico"
                  v-model="item.basicCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("costo_basico") }}</span>
                <v-text-field
                  data-vv-as="Costo péon"
                  :label="$t('Costo') + $t('Peon') + '*'"
                  name="assistantCost"
                  v-model="item.assistantCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("assistantCost") }}</span>
                <v-text-field
                  data-vv-as="Costo demora origen"
                  :label="$t('Costo') + $t('Demora') + ' ' + $t('Origen') + '*'"
                  name="delayCost"
                  v-model="item.delayCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("delayCost") }}</span>
                <v-text-field
                  data-vv-as="Costo demora destino"
                  :label="$t('Costo') + $t('Demora') + ' ' + $t('Destino') + '*'"
                  name="delayDestinationCost"
                  v-model="item.delayDestinationCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("delayDestinationCost") }}</span>
                <v-text-field data-vv-as="Costo estadías *" label="Costo estadías*" name="stayCost" v-model="item.stayCost" v-validate="'required|decimal'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("stayCost") }}</span>
                <v-text-field
                  data-vv-as="Costo vuelta"
                  :label="$t('Costo') + $t('Vuelta') + '*'"
                  name="turnCost"
                  v-model="item.turnCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("turnCost") }}</span>
                <v-text-field
                  data-vv-as="Devoluciones AM"
                  label="Devoluciones AM"
                  name="devolutionsAMCost"
                  v-model="item.devolutionsAMCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("devolutionsAMCost") }}</span>
                <v-text-field
                  data-vv-as="Guarda en depósito"
                  label="Guarda en depósito"
                  name="saveWarehouseCost"
                  v-model="item.saveWarehouseCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("saveWarehouseCost") }}</span>
              </div>

              <div class="col-md-4">
                <v-text-field
                  data-vv-as="Plus nocturno"
                  :label="$t('Plus_nocturno')"
                  name="plus_nocturno"
                  v-model="item.nigthPlusCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("costo_basico") }}</span>
                <v-text-field
                  data-vv-as="Regreso con devolución"
                  :label="$t('Regreso_devolucion') + '*'"
                  name="regreso_devolucion"
                  v-model="item.devolutionReturnCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("assistantCost") }}</span>
                <v-text-field
                  data-vv-as="Larga distancia"
                  :label="$t('Larga_distancia')"
                  name="Larga_distancia"
                  v-model="item.longDistanceCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("Larga_distancia") }}</span>
                <v-text-field
                  data-vv-as="Falso flete *"
                  label="Falso flete *"
                  name="falso_flete"
                  v-model="item.falseTransportationCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("falso_flete") }}</span>
                <v-text-field
                  data-vv-as="Fin de semana y feriados"
                  :label="$t('Finde_feriados')"
                  name="turnCost"
                  v-model="item.wekendAndHolidayCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("weekends") }}</span>
                <v-text-field data-vv-as="IMO" :label="$t('IMO')" name="turnCost" v-model="item.imoCost" v-validate="'required|decimal'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("imo") }}</span>
                <v-text-field
                  data-vv-as="Devoluciones PM"
                  label="Devoluciones PM"
                  name="devolutionsPMCost"
                  v-model="item.devolutionsPMCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("devolutionsPMCost") }}</span>
                <v-text-field data-vv-as="desvio" label="Desvío" name="Deviation" v-model="item.deviationCost" v-validate="'required|decimal'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("Deviation") }}</span>
                <v-text-field
                  data-vv-as="Devolucion mismo dia"
                  label="Devolucion mismo dia"
                  name="sameDayReturnCost"
                  v-model="item.sameDayReturnCost"
                  v-validate="'required|decimal'"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("sameDayReturnCost") }}</span>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn :loading="loading" v-if="!isViewModal()" color="success" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalMixin from "@/mixins/ModalMixin.vue";
import { zoneService } from "@/libs/ws/zoneService";
import { typeVehicleService } from "@/libs/ws/typeVehicleService";
import { typeChargeService } from "@/libs/ws/typeChargeService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  mixins: [ModalMixin],
  data() {
    return {
      tipoDeVehiculos: [],
      tipoDeCagas: [],
      zonas: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadZonas();
    this.loadTipoVehiculo();
    this.loadTipoCarga();
  },
  methods: {
    loadZonas() {
      zoneService
        .filter(" ", 0, zoneService.ZONES_PAGINATION, 1)
        .then((response) => {
          this.zonas = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTipoVehiculo() {
      typeVehicleService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.tipoDeVehiculos = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTipoCarga() {
      typeChargeService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.tipoDeCagas = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem() {
      if(this.loading)
        return;
      this.loading = true;

      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>
