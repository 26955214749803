import axios from "axios";
import { is, curryN, gte } from "ramda";
import https from "https";

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number);
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  );
});
const in200s = isWithin(200, 299);

//console.log(token)
const generalDataApi = (url, timeout) => {
  const token = localStorage.getItem("token");

  return axios.create({
    baseURL: url,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + token,
    },
    timeout: timeout,
  });
};

function make_post_request_no_auth(url, timeout, section, param, intento = 0) {
  if (intento > 3)
    return {
      status: false,
      msg: "Error en el sistema luego de reintentar conexión, CODE: MaxRetry",
      code: 3,
    };

  return generalDataApi(url, timeout)
    .post(section, param, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      if (in200s(response.status)) {
        if (response.data !== undefined) {
          return response.data;
        }
        return {
          status: false,
          msg: "Error en el sistema, CODE: 501",
          code: 501,
        };
      }
      return {
        status: false,
        msg:
          "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
          response.status,
        code: response.status,
      };
    })
    .catch((e) => {
      throw e;
    });
}

function make_post_request(url, timeout, section, param, intento = 0) {
  if (intento > 3)
    return {
      status: false,
      msg: "Error en el sistema luego de reintentar conexión, CODE: MaxRetry",
      code: 3,
    };

  return generalDataApi(url, timeout)
    .post(section, param)
    .then((response) => {
      if (in200s(response.status)) {
        if (response.data !== undefined) {
          return response.data;
        }
        return {
          status: false,
          msg: "Error en el sistema, CODE: 501",
          code: 501,
        };
      }
      return {
        status: false,
        msg:
          "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
          response.status,
        code: response.status,
      };
    })
    .catch((e) => {
      throw e;
    });
}

function make_get_request(url, timeout, section, intento = 0) {
  if (intento > 3)
    return {
      status: false,
      msg: "Error en el sistema luego de reintentar conexión, CODE: MaxRetry",
      code: 3,
    };

  return generalDataApi(url, timeout)
    .get(section)
    .then((response) => {
      if (in200s(response.status)) {
        if (response.data !== undefined) {
          return response.data;
        }
        return {
          status: false,
          msg: "Error en el sistema, CODE: 501",
          code: 501,
        };
      }
      return {
        status: false,
        msg:
          "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
          response.status,
        code: response.status,
      };
    })
    .catch((e) => {
      console.log("requestService.get");
      throw e;
    });
}

function make_get_param_request(url, timeout, section, params, intento = 0) {
  if (intento > 3)
    return {
      status: false,
      msg: "Error en el sistema luego de reintentar conexión, CODE: MaxRetry",
      code: 3,
    };

  return generalDataApi(url, timeout)
    .get(section, params)
    .then((response) => {
      if (in200s(response.status)) {
        if (response.data !== undefined) {
          return response.data;
        }
        return {
          status: false,
          msg: "Error en el sistema, CODE: 501",
          code: 501,
        };
      }
      return {
        status: false,
        msg:
          "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
          response.status,
        code: response.status,
      };
    })
    .catch((e) => {
      console.log("requestService.get");
      throw e;
    });
}

function make_delete_request(url, timeout, section, param, intento = 0) {
  if (intento > 3)
    return {
      status: false,
      msg: "Error en el sistema luego de reintentar conexión, CODE: MaxRetry",
      code: 3,
    };

  return generalDataApi(url, timeout)
    .delete(section, { data: param })
    .then((response) => {
      if (in200s(response.status)) {
        if (response.data !== undefined) {
          return response.data;
        }
        return {
          status: false,
          msg: "Error en el sistema, CODE: 501",
          code: 501,
        };
      }
      return {
        status: false,
        msg:
          "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
          response.status,
        code: response.status,
      };
    })
    .catch((e) => {
      console.log("requestService.get");
      throw e;
    });
}

function make_put_request(url, timeout, section, param, intento = 0) {
  if (intento > 3)
    return {
      status: false,
      msg: "Error en el sistema luego de reintentar conexión, CODE: MaxRetry",
      code: 3,
    };

  return generalDataApi(url, timeout)
    .put(section, param)
    .then((response) => {
      if (in200s(response.status)) {
        if (response.data !== undefined) {
          return response.data;
        }
        return {
          status: false,
          msg: "Error en el sistema, CODE: 501",
          code: 501,
        };
      }
      return {
        status: false,
        msg:
          "Hubo un error en la conexión de internet, reintente nuevamente, CODE1: " +
          response.status,
        code: response.status,
      };
    })
    .catch((e) => {
      throw e;
    });
}

function handle_error(log, e) {
  if (e.response != undefined) {
    return e.response.data;
  }

  const code = e.request !== undefined ? e.request.status : e.code;
  if (code != undefined) {
    if (code === "ECONNABORTED")
      return {
        status: false,
        msg: "No se puede alcanzar el servidor, verifique su conexión.",
        code: e.code,
        exception: e,
      };

    if (code == 400) {
      return {
        status: false,
        msg: "Bad Request, CODE:" + code,
        code: code,
        exception: e,
      };
    }

    if (code == 401)
      return {
        status: false,
        msg: "Acceso no autorizado, CODE:" + code,
        code: code,
        exception: e,
      };

    if (code == 403)
      return {
        status: false,
        msg: "Forbidden, CODE:" + code,
        code: code,
        exception: e,
      };

    if (code == 404)
      return {
        status: false,
        msg: "Not Found, CODE:" + code,
        code: code,
        exception: e,
      };

    if (code == 405)
      return {
        status: false,
        msg: "Method Not Allowed, CODE:" + code,
        code: code,
        exception: e,
      };

    if (code == 408)
      //Request Timeout
      return {
        status: false,
        msg: "Request Timeout, CODE:" + code,
        code: code,
        exception: e,
      };

    if (code == 410)
      return {
        status: false,
        msg: "Request Gone, CODE:" + code,
        code: code,
        exception: e,
      };

    if (code == 500)
      return {
        status: false,
        msg: "Internal Server Error on request, CODE:" + code,
        code: code,
        exception: e,
      };

    return {
      status: false,
      msg:
        "Hubo un error en la conexión de internet, reintente nuevamente, CODE:" +
        code,
      code: code,
      exception: e,
    };
  }
  console.log("Error requestService: " + log);
  console.log(e);
  return {
    status: false,
    msg: "Hubo un error en la conexión de internet, reintente nuevamente.",
    code: -1,
    exception: e,
  };
}

export const requestService = {
  make_post_request_no_auth,
  make_post_request,
  make_get_request,
  make_put_request,
  make_delete_request,
  handle_error,
  make_get_param_request,
};
