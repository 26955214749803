<template>
  <div :style="selected.length > 0 ? 'margin-bottom:200px' : ''">
    <Title :pages="[{ icon: 'route', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <ModalRemitos
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + $t('Remitos')"
        modal_title="Remitos"
        :formBtn="formBtnRemito"
        :dialog="dialogRemitos"
        :service="serviceRemitos"
        @updateDialog="updateDialogRemitos"
        @loadList="loadRemitosPendientes()"
        :item="mainItemRemito"
        @updateItem="
          (newItem) => {
            mainItemRemito = newItem;
          }
        "
        :sinRuta="true"
      />
      <v-menu offset-y v-if="create_access">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Rutear
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="dialog_rutas = true" style="cursor:pointer">{{ $t("Crear_nueva_ruta") }}</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="cursor:pointer" @click="dialog_asignar_ruta = true">{{ $t("Asignar_remitos_ruta_creada") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn :disabled="selected.length <= 0" color="primary" outlined class="ml-2" @click="dialog_descartar = true">
        Descartar remitos
      </v-btn>

      <!--Filter-->
      <div class="row mb-0 mt-2" v-if="list_access">
        <div class="col-md-12 pb-0">
          <div class="filter" style="border-radius: 0px">
            <div class="row p-2">
              <div class="col-md-11">
                <div class="d-flex align-items-center">
                  <h4 class="title">{{ $t("Filtros") }}</h4>
                  <font-awesome-icon icon="filter" />

                  <p v-if="open_filter" class="ml-3" style="margin-bottom:0px!important">
                    {{ $t("Seleccion_filtros") }}
                  </p>
                  <span v-else>
                    <v-chip outlined class="ml-3 mt-1" color="#666" v-if="delivery_note_filter != ''"> {{ $t("Remitos") }}: {{ delivery_note_filter }}</v-chip>
                    <v-chip outlined class="ml-3 mt-1" color="#666" v-if="cliente_filter != ''">
                      {{ $t("Cliente") }}: {{ cliente_filter == null ? (cliente_filter = "") : cliente_filter.nameClient }}</v-chip
                    >
                    <v-chip outlined class="ml-3 mt-1" color="#666" v-if="subcliente_filter != ''">
                      Sub {{ $t("Cliente") }}: {{ subcliente_filter == null ? (subcliente_filter = "") : subcliente_filter.nameSubClient }}</v-chip
                    >

                    <v-chip outlined class="ml-3 mt-1" color="#666" v-if="provincia_filter != ''">
                      {{ $t("Provincia") }}: {{ provincia_filter == null ? (provincia_filter = "") : provincia_filter.name }}</v-chip
                    >

                    <v-chip outlined class="ml-3 mt-1" color="#666" v-for="localidad in localidad_filter" :key="localidad.id"> {{ $t("Localidades") }}: {{ localidad.name }}</v-chip>
                    <v-chip outlined class="ml-3 mt-1" color="#666" v-if="state_filter != ''"> {{ $t("Estado") }}: {{ state_filter == null ? (state_filter = "") : state_filter.name }}</v-chip>
                    <v-chip outlined class="ml-3 mt-1" color="#666" v-if="cluster_filter != ''">
                      Cluster:
                      {{ cluster_filter == null ? (cluster_filter = "") : cluster_filter.name }}</v-chip
                    >
                  </span>
                </div>
              </div>
              <div class="col-md-1">
                <font-awesome-icon class="cursor" icon="chevron-down" v-if="!open_filter" @click="open_filter = true" />
                <font-awesome-icon v-else class="cursor" icon="chevron-up" @click="open_filter = false" />
              </div>
            </div>
            <hr v-if="open_filter" />
            <div class="row" v-if="open_filter">
              <div class="col-md-3">
                <v-text-field data-vv-as="remito" :label="$t('Remito')" name="delivery_note" v-model="delivery_note_filter"></v-text-field>
              </div>

              <div class="col-md-3">
                <v-combobox v-model="cliente_filter" name="client" item-text="nameClient" item-value="id" :items="clients" label="Cliente" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col-md-3">
                <v-combobox
                  v-model="subcliente_filter"
                  name="subclient"
                  :item-text="subcli"
                  item-value="id"
                  :items="subclients"
                  label="Subclientes"
                  autocomplete="off"
                  :clearable="true"
                  :hide-no-data="!search"
                  @keyup="loadSubclients()"
                  :loading="loading_subclientes"
                ></v-combobox>
              </div>
              <div class="col-md-3">
                <v-combobox v-model="state_filter" name="estado" item-text="name" item-value="id" label="Estado" autocomplete="off" :items="states"></v-combobox>
              </div>
            </div>
            <!-- fin row1 -->

            <div class="row" v-if="open_filter">
              <div class="col-md-3">
                <v-combobox v-model="cluster_filter" name="cluster" item-text="name" item-value="id" :items="clusters" label="Cluster" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col-md-3">
                <v-combobox
                  v-model="provincia_filter"
                  name="province"
                  :item-text="provincia_qty"
                  item-value="id"
                  label="Provincia"
                  autocomplete="off"
                  :clearable="true"
                  :items="provincias"
                  v-on:change="loadLocalidadesOfProvice()"
                ></v-combobox>
                <!--  -->
              </div>
              <div class="col-md-3">
                <v-combobox
                  :disabled="provincia_filter == '' || provincia_filter == null || loading_locality"
                  v-model="localidad_filter"
                  name="localidad"
                  :item-text="localidad_qty"
                  item-value="id"
                  label="Localidades"
                  autocomplete="off"
                  :clearable="true"
                  :items="localidades"
                  multiple
                ></v-combobox>
              </div>

              <div class="col-md-3 d-flex align-items-right justify-content-end pt-8 ">
                <download-excel v-show="false" :data="list" :fields="header_excel" worksheet="My Worksheet" name="ruteo.xls" ref="refDownload"> </download-excel>
                <v-btn color="blue-grey" @click="$refs.refDownload.$el.click()" class="mr-2 text-white">
                  Exportar
                </v-btn>
                <v-btn color="primary" dark @click="loadRemitosPendientes()">{{ $t("Buscar") }}</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-0" v-if="list_access">
        <div class="col-md-12 pt-0 pb-0">
          <div id="mapa">
            <GeoMultipleMarkers :remitos="list" :ruta="fakeRuta" :remitos_seleccionados="selected" />
          </div>
        </div>
      </div>
      <div class="row mt-0" v-if="list_access">
        <div class="col-md-12 ">
          <v-alert border="left" class="mb-0" color="#2e3d45" dark style="font-weight:600;border-radius:0px;font-size:13px">
            <div class="row">
              <div class="col">{{ $t("Pallets") }}: {{ this.pallets_acum }}</div>
              <div class="col">{{ $t("Bultos") }}: {{ this.bultos_acum }}</div>
              <div class="col">Kg: {{ this.kg_acum }}</div>
              <div class="col">M<sup>3</sup>: {{ this.m3_acum }}</div>
              <div class="col">
                {{ $t("Valor_declarado") }}:
                {{ new Intl.NumberFormat("es-EN").format(this.valor_acum) }}
              </div>
              <div class="col">
                {{ $t("Rack") }}:
                {{ this.rack_acum }}
              </div>
              <div class="col">
                {{ $t("Bobina") }}:
                {{ this.bobina_acum }}
              </div>
              <div class="col">
                {{ $t("Tambores") }}:
                {{ this.tambores_acum }}
              </div>
            </div>
          </v-alert>
        </div>
      </div>
      <v-card v-if="list_access">
        <div class="row">
          <div class="col-md-12 p-4">
            <v-card-title>
              <span class="text-uppercase">{{ page_title }}</span>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
            </v-card-title>
            <hr class="mb-0" />
            <v-data-table :search="search" :headers="headers" :items="list" :loading="loading" :loading-text="$t('cargando_espere')" v-model="selected" show-select :single-select="false">
              <template v-slot:item="props">
                <tr>
                  <td style="width:5%">
                    <v-checkbox :input-value="props.isSelected" style="margin:0px;padding:0px ;" hide-details @change="props.select($event)" />
                  </td>
                  <td style="width:10%">
                    {{ props.item.remito }}
                  </td>
                  <td style="width:10%">{{ props.item.client.nameClient }}</td>
                  <td style="width:10% ">
                    {{ props.item.subClient.nameSubClient }}
                  </td>
                  <td style="width:10%">
                    {{ props.item.subClient.subClientAddress != null ? props.item.subClient.subClientAddress : " " }}
                  </td>
                  <td style="width:10%">
                    {{ props.item.subClient.locality.name != null ? props.item.subClient.locality.name : " " }}
                  </td>
                  <td style="width:5%">
                    {{ props.item.pallets }}
                  </td>
                  <td style="width:5%">
                    {{ props.item.packages }}
                  </td>
                  <td style="width:5%">
                    {{ props.item.cubicMeters }}
                  </td>
                  <td style="width:5%">
                    {{ props.item.kg }}
                  </td>
                  <td style="width:5%">${{ new Intl.NumberFormat("es-EN").format(props.item.declaredValue) }}</td>
                  <td>
                    {{ props.item.rack }}
                  </td>
                  <td>
                    {{ props.item.bobina }}
                  </td>
                  <td>
                    {{ props.item.tambores }}
                  </td>

                  <td style="min-width:100px">
                    {{ props.item.observation }}
                  </td>

                  <td style="min-width:105px" v-html="getState(props.item.estate.id)" :title="props.item.fechaEstado + ' ' + props.item.horaEstado + ' - ' + props.item.usuarioEstado"></td>

                  <td class="justify-content-center px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="blue" class="mr-2" @click="editItemRemitos(props.item)">
                          edit
                        </v-icon>
                      </template>
                      <span>{{ $t("Editar") }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItemRemitos(props.item)" v-if="delete_access">
                          remove_red_eye
                        </v-icon>
                      </template>
                      <span>{{ $t("Ver") }}</span>
                    </v-tooltip>
                    <!-- 
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          small
                          color="red"
                          class="mr-1"
                          @click="deleteItemRemito(props.item)"
                          v-if="update_access"
                        >
                          delete
                        </v-icon>
                      </template>
                      <span>{{ $t("Eliminar") }}</span>
                    </v-tooltip> -->
                  </td>
                </tr>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning"> {{ $t("sin_resultados") + search }}". </v-alert>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>

      <v-dialog v-model="dialog_remitos_no_ingresados" width="500">
        <v-card>
          <v-card-title class="headline">
            {{ $t("Error") }}
          </v-card-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_ingresados") + cant_remitos_ingresados }}
          </v-list-item-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_sin_ingresar") }}
          </v-list-item-title>
          <v-card-text class="pb-0" style="padding-top:0px !important ; height:30px" v-for="(item, i) in remitos_no_ingresados" :key="i">
            <v-list-item>
              <v-list-item-title> • {{ item.numRemito }}</v-list-item-title>
            </v-list-item>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialog_remitos_no_ingresados = false">
              {{ $t("Cerrar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div>
      <v-dialog v-model="dialog_descartar" width="550">
        <v-card>
          <v-card-title class="headline">
            Descartar remitos
          </v-card-title>
          <v-card-text>
            <p class="fs-16 mt-5 text-center" v-if="selected.length > 1">¿Estas seguro que quieres descartar los {{ selected.length }} remitos seleccionados?</p>
            <p class="fs-16 mt-5 text-center" v-if="selected.length == 1">¿Estas seguro que quieres descartar el remito seleccionado?</p>
          </v-card-text>

          <v-divider class="mb-0 mt-0"></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialog_descartar = false">
              {{ $t("Cerrar") }}
            </v-btn>
            <v-btn color="green" :loading="loading_descartar" text @click="descartarRemitos">
              Descartar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_remitos_no_ingresados" width="500">
        <v-card>
          <v-card-title class="headline">
            {{ $t("Error") }}
          </v-card-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_ingresados") + cant_remitos_ingresados }}
          </v-list-item-title>
          <v-list-item-title class="ml-4 mt-2">
            {{ $t("Remitos_sin_ingresar") }}
          </v-list-item-title>
          <v-card-text class="pb-0" style="padding-top:0px !important ; height:30px" v-for="(item, i) in remitos_no_ingresados" :key="i">
            <v-list-item>
              <v-list-item-title> • {{ item.numRemito }}</v-list-item-title>
            </v-list-item>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialog_remitos_no_ingresados = false">
              {{ $t("Cerrar") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-system-bar
        v-show="selected.length > 0"
        dark
        color="primary"
        :style="'z-index: 100;position:fixed;bottom:0px;height:55px;width:' + ($store.state.sidebar.open ? 'calc(100% - 240px)' : 'calc(100% - 56px)')"
        class="cursor"
      >
        <div class="row">
          <div class="col-md-10" @click="dialog_selected = true">
            <span style="color:white" class=" title">
              <font-awesome-icon icon="receipt" class="ml-2 mr-2" />
              Remitos seleccionados:
              {{ selected.length }}
            </span>
          </div>
          <div class="col-md-2">
            <v-btn small color="" dark @click="selected = []">Limpiar seleccion</v-btn>
          </div>
        </div>
      </v-system-bar>

      <v-dialog v-model="dialog_selected" fullscreen transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark class="headline">
            <v-btn class="ml-4" icon color="error" @click="dialog_selected = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Remitos seleccionados</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-card-text class="pb-0" style="padding-top:0px !important">
            <div class="row mt-0">
              <div class="col-md-12 ">
                <v-alert border="left" class="mb-0" color="#2e3d45" dark style="font-weight:600;border-radius:0px;font-size:13px">
                  <div class="row">
                    <div class="col">{{ $t("Pallets") }}: {{ this.pallets_acum }}</div>
                    <div class="col">{{ $t("Bultos") }}: {{ this.bultos_acum }}</div>
                    <div class="col">Kg: {{ this.kg_acum }}</div>
                    <div class="col">M<sup>3</sup>: {{ this.m3_acum }}</div>
                    <div class="col">
                      {{ $t("Valor_declarado") }}:
                      {{ new Intl.NumberFormat("es-EN").format(this.valor_acum) }}
                    </div>
                    <div class="col">
                      {{ $t("Rack") }}:
                      {{ this.rack_acum }}
                    </div>
                    <div class="col">
                      {{ $t("Bobina") }}:
                      {{ this.bobina_acum }}
                    </div>
                    <div class="col">
                      {{ $t("Tambores") }}:
                      {{ this.tambores_acum }}
                    </div>
                  </div>
                </v-alert>
              </div>
            </div>

            <v-data-table :headers="headers" :items="selected" :loading="loading" :loading-text="$t('cargando_espere')" style="border: solid 1px #1c1c1c" class="mt-4">
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.item.remito }}</td>
                  <td>{{ props.item.client.nameClient }}</td>
                  <td>
                    {{ props.item.subClient != null ? props.item.subClient.nameSubClient : " " }}
                  </td>
                  <td>
                    {{ props.item.subClient != null ? props.item.subClient.subClientAddress : " " }}
                  </td>
                  <td>
                    {{ props.item.subClient.locality.name != null ? props.item.subClient.locality.name : " " }}
                  </td>
                  <td>
                    {{ props.item.pallets }}
                  </td>
                  <td>
                    {{ props.item.packages }}
                  </td>
                  <td>
                    {{ props.item.cubicMeters }}
                  </td>
                  <td>
                    {{ props.item.kg }}
                  </td>
                  <td>{{ new Intl.NumberFormat("es-EN").format(props.item.declaredValue) }}</td>
                  <td>
                    {{ props.item.rack }}
                  </td>
                  <td>
                    {{ props.item.bobina }}
                  </td>
                  <td>
                    {{ props.item.tambores }}
                  </td>

                  <td style="min-width:135px" v-html="getState(props.item.estate.id)"></td>

                  <td class="justify-content-center text-center px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="red" @click="removeFromSelected(props.index)" v-if="update_access">
                          indeterminate_check_box
                        </v-icon>
                      </template>
                      <span>{{ $t("Remover_seleccionado") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
              <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning"> {{ $t("sin_resultados") + search }}". </v-alert>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>

      <ModalRutas
        v-show="false"
        :modal_title="$t('Rutas')"
        :formBtn="formBtn"
        :dialog="dialog_rutas"
        :item="mainItemRuta"
        :service="service"
        :remitos_selected="selected"
        :m3Acumulados="m3_acum"
        @updateDialog="updateDialogRutas"
        @updateItem="
          (newItem) => {
            mainItemRuta = newItem;
          }
        "
        @id_rutaCreada="AsignarRemitosARuta"
        @clearSelected="clearSelected"
      />
      <AsignarRuta
        :modal_title="$t('Asignar_remitos_ruta_creada')"
        :dialog="dialog_asignar_ruta"
        :remitos_selected="selected"
        :ruta_id="ruta_id"
        @updateDialog="updateDialogAsignarRuta"
        @reloadList="loadRemitosPendientes"
        @clearSelected="clearSelected"
      />

      <DialogDelete :dialog_delete="dialog_deleteRemito" @updateDialogDelete="dialog_deleteRemito = false" @deleteItemConfirm="deleteItemConfirmRemito" />
    </div>
  </div>
</template>

<script>
import ModalRemitos from "./ModalRemitos.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import { clientService } from "@/libs/ws/clientService";
import { subClientService } from "@/libs/ws/subClientService";
import { remitosService } from "@/libs/ws/remitosService";
import { countryService } from "@/libs/ws/countryService";
import { rutasService } from "@/libs/ws/rutasService";
import GeoMultipleMarkers from "./GeoMultipleMarkers.vue";
import readXlsxFile from "read-excel-file";
import ModalRutas from "./Modal.vue";
import AsignarRuta from "./AsignarRuta.vue";
import { clusterService } from "@/libs/ws/clusterService";

export default {
  components: {
    Title,
    ModalRemitos,
    GeoMultipleMarkers,
    ModalRutas,
    AsignarRuta,
    DialogDelete,
  },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      search: "",
      ruta_id: 0,
      loading: false,
      dialog_selected: false,
      loading_subclientes: false,
      loading_descartar: false,
      dialog_rutas: false,
      dialog_asignar_ruta: false,
      dialog_descartar: false,
      selected: [],
      fakeRuta: {},
      excelFile: null,
      dialog_import: false,
      excelupload: [],
      list: [],
      remitos_pendientes: [],
      ruta: {},
      page_title: "Ruteo",
      serviceRemitos: remitosService,
      dialogRemitos: false,
      editedIndexRemito: -1,
      viewdIndexRemito: -1,
      deleteIndexRemito: -1,
      clients: [],
      subclients: [],
      clusters: [],
      states: [],
      localidades: [],
      localidades_de_remitos_pendientes: [],
      provincias: [],
      localidad_filter: [],
      cluster_filter: "",
      delivery_note_filter: "",
      cliente_filter: "",
      subcliente_filter: "",
      provincia_filter: "",
      zona_filter: "",
      state_filter: "",
      mainItemRemito: {
        IdTrip: "",
        Orden: "",
        IdClient: "",
        IdSubClient: "",
        Remito: "",
        SubClients: "",
        Clients: "",
        declaredValue: 0,
        pallets: 0,
        packages: 0,
        kg: 0,
        cubicMeters: 0,
        images: "",
        seleccionado: false,
      },
      defaultItemRemito: null,
      open_filter: false,
      loading_locality: false,
      headers: [
        {
          text: "N°" + this.$t("Remito"),
          align: "left",
          sortable: true,
          value: "Remito",
          class: "lighten-2",
        },

        {
          text: this.$t("Cliente"),
          align: "left",
          sortable: true,
          value: "client.nameClient",
          class: "lighten-2",
        },
        {
          text: "Sub" + this.$t("Cliente"),
          align: "left",
          sortable: true,
          value: ".subClient.nameSubClient",
          class: "lighten-2",
        },
        {
          text: this.$t("Direccion"),
          align: "left",
          sortable: true,
          value: "subclients.subClientAddress",
          class: "lighten-2",
        },
        {
          text: this.$t("Localidad"),
          align: "left",
          sortable: true,
          value: "subclients.locality.name",
          class: "lighten-2",
        },
        {
          text: "Pall",
          align: "left",
          sortable: true,
          value: "pallets",
          class: "lighten-2",
        },
        {
          text: "Bul",
          align: "left",
          sortable: true,
          value: "packages",
          class: "lighten-2",
        },
        {
          text: "M3",
          align: "left",
          sortable: true,
          value: "cubicMeters",
          class: "lighten-2",
        },
        {
          text: "KG",
          align: "left",
          sortable: true,
          value: "kg",
          class: "lighten-2",
        },
        {
          text: this.$t("Valor_declarado"),
          align: "left",
          sortable: true,
          value: "declaredValue",
          class: "lighten-2",
        },
        {
          text: this.$t("Rack"),
          align: "left",
          sortable: true,
          value: "rack",
          class: "lighten-2",
        },
        {
          text: "Bob",
          align: "left",
          sortable: true,
          value: "bobina",
          class: "lighten-2",
        },
        {
          text: "Tam",
          align: "left",
          sortable: true,
          value: "tambores",
          class: "lighten-2",
        },
        {
          text: "Obs.",
          align: "left",
          sortable: true,
          value: "observation",
          class: "lighten-2",
        },
        {
          text: this.$t("Estado"),
          align: "left",
          sortable: true,
          value: "state",
          class: "lighten-2",
        },
        {
          text: this.$t("Accion"),
          align: "left",
          sortable: true,
          value: "enable",
          class: "lighten-2",
        },
      ],
      mainItemRuta: {
        id: "",
        numTrip: "",
        time: "00:00:00",
        date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        origen: "",
        driver: "",
        vehicle: "",
        transport: { nameTransport: "", type: "", maxCharge: "" },
        typeCharge: "",
        destination: "",
        zone: "",
        typeTrip: "",
        totalOcupation: "",
        initialDate: "",
        initialCharge: "",
        finishedCharge: "",
        finishedTrip: "",
        ocupationState: "",
        companyCustody: null,
        quantityModules: 0,
        aditionals: [],
        basicCost: "",
        totalCost: "",
        driverLatitud: "",
        driverLongitud: "",
        idOrganizacion: "",
        observation1: "",
        observation2: "",
        isActive: true,
        delivered: [],
      },
      defaultItemRuta: null,
      service: rutasService,
      bultos_acum: 0,
      kg_acum: 0,
      m3_acum: 0,
      pallets_acum: 0,
      valor_acum: 0,
      rack_acum: 0,
      bobina_acum: 0,
      tambores_acum: 0,
      dialog_deleteRemito: false,
      remitos_no_ingresados: [],
      dialog_remitos_no_ingresados: false,
      remitos_upload: [],
      cant_remitos_ingresados: 0,
      import_types: [
        { id: 0, name: "Importacion basica" },
        { id: 1, name: "Importacion completa" },
      ],
      import_type: null,
      importObject: {
        NameClient: "",
        ExternalCodSubClient: "",
        NameSubClient: "",
        CUITSubClient: "",
        AddressSubClient: "",
        CPSubClient: "",
        LocalitySubClient: "",
        ProvinceSubClient: "",
        CountrySubClient: "",
        NumRemito: "",
        DeclaredValue: "",
        Weight: "",
        CubicMeters: "",
        Estate: "",
        Pallet: "",
        Package: "",
        rack: 0,
        bobina: 0,
        tambores: 0,
        Observation: "",
      },
      header_excel: {
        Remito: "remito",
        Cliente: "client.nameClient",
        Subcliente: "subClient.nameSubClient",
        Direccion: "subClient.subClientAddress",
        Localidad: "subClient.locality.name",
        Pallets: "pallets",
        Bultos: "packages",
        M3: "cubicMeters",
        KG: "kg",
        VD: "declaredValue",
        Rack: "rack",
        Bobinas: "bobina",
        Tambores: "tambores",
        Estado: "estate.name",
        Fecha: "fechaEstado",
        Hora: "horaEstado",
        Usuario: "usuarioEstado",
      },
    };
  },
  mounted() {
    this.defaultItemRemito = Object.assign({}, this.mainItemRemito);
    this.defaultItemRuta = Object.assign({}, this.mainItemRuta);
    this.loadClients();
    this.loadSubclients();
    //this.loadProvincias();
    this.loadRemitosPendientes();
    this.loadStates();
    this.loadClusters();
  },
  computed: {
    formBtnRemito() {
      if (this.editedIndexRemito === -1 && this.viewdIndexRemito === -1) {
        return "Crear";
      } else if (this.viewdIndexRemito === -1) {
        return "Editar";
      }
      return "";
    },
  },
  watch: {
    selected() {
      this.CalcularAcumulado();
    },
    subcliente_filter() {
      this.syncSubclientSearch();
    },
  },
  methods: {
    subcli: (item) => (item.id == 0 ? "" : item.nameSubClient + " (" + item.subClientAddress + ")"),
    clearSelected() {
      this.selected = [];
      this.mainItemRuta = Object.assign({}, this.defaultItemRuta);
    },
    removeFromSelected(index) {
      this.selected.splice(index, 1);
    },
    loadSubclients() {
      this.loading_subclientes = true;
      subClientService
        .filter(this.search == "" ? " " : this.search, 0, 3000, 1) //Locksley tiene mas de 2000
        .then((response) => {
          this.subclients = response.list;
          this.loading_subclientes = false;
        })
        .catch((e) => {
          console.log("service.load error");
          this.loading_subclientes = false;
          console.log(e);
        });
    },
    loadClusters() {
      clusterService
        .getAll()
        .then((response) => {
          this.clusters = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadStates() {
      remitosService
        .GetFilterStates()
        .then((response) => {
          this.states = response;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    provincia_qty: (item) => (item.id == 0 ? "" : item.name + " (" + item.quantity + ")"),
    localidad_qty: (item) => (item.id == 0 ? "" : item.name + " (" + item.quantity + ")"),
    descartarRemitos() {
      this.loading_descartar = true;

      var remitos = [];
      this.selected.forEach((element) => {
        remitos.push(element.id);
      });

      var params = {
        remitos: remitos,
      };

      remitosService
        .descartarRemitos(params)
        .then((response) => {
          console.log(response);
          this.loadRemitosPendientes();
          this.dialog_descartar = false;
          this.loading_descartar = false;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
          this.loading_descartar = false;
        });
    },
    loadRemitosPendientes() {
      this.loading = true;
      //this.selected = [];

      var params = {
        client_id: this.cliente_filter != "" ? this.cliente_filter.id : 0,
        remito_id: this.delivery_note_filter,
        IdSubClient: this.subcliente_filter != "" ? this.subcliente_filter.id : 0,
        Localidad: this.getLocalidadesFilters(),
        Estado: this.state_filter != "" ? this.state_filter.id : 0,
        IdCluster: this.cluster_filter != "" ? this.cluster_filter.id : 0,
      };

      remitosService
        .filterRuteo(params)
        .then((response) => {
          this.list = response.list.reverse();
          this.provincias = response.provincias;
          console.log(response);
          this.localidades_de_remitos_pendientes = response.localidades;
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    getLocalidadesFilters() {
      var arrayLocalidades = [];
      if (this.localidad_filter.length != 0) {
        this.localidad_filter.forEach((element) => {
          arrayLocalidades.push(element.id);
        });
      }
      return arrayLocalidades;
    },
    updateDialogRemitos(value, tipo = 0) {
      if (!value) {
        this.closeRemitos(tipo);
        this.loadRemitosPendientes();
        return;
      }
    },
    viewItemRemitos(item) {
      this.viewdIndexRemito = 1;
      this.mainItemRemito = Object.assign({}, item);
      this.dialogRemitos = true;
    },
    editItemRemitos(item) {
      this.errors.clear();
      this.editedIndexRemito = 1;
      this.mainItemRemito = Object.assign({}, item);
      this.dialogRemitos = true;
    },
    closeRemitos(tipo) {
      if (tipo == 0) {
        this.mainItemRemito = Object.assign({}, this.defaultItemRemito);
        this.dialogRemitos = false;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
      } else if (tipo == 1) {
        var orden = 0;
        orden = this.mainItemRemito.Orden + 1;
        this.mainItemRemito = Object.assign({}, this.defaultItemRemito);
        this.mainItemRemito.Orden = orden;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.dialogRemitos = true;
      } else {
        this.mainItemRemito.Orden = this.mainItemRemito.Orden + 1;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.dialogRemitos = true;
      }
    },

    addRemitoARuta() {
      this.remitos_pendientes = [];
      this.selected.forEach((element) => {
        this.remitos_pendientes.push(element);
      });
    },
    updateDialogRutas(value) {
      if (!value) {
        this.dialog_rutas = false;
        return;
      }
    },
    updateDialogAsignarRuta(value) {
      if (!value) {
        this.dialog_asignar_ruta = false;
        this.loadRemitosPendientes();
        this.selected = [];
      }
    },
    AsignarRemitosARuta(id_ruta, remitos) {
      const params = {
        ruta_id: parseInt(id_ruta),
        remitos: [],
      };
      remitos.forEach((element) => {
        params.remitos.push(element.id);
      });
      remitosService
        .AsignInBagToExistingtRoute(params)
        .then((response) => {
          this.loadRemitosPendientes();
        })
        .catch((response) => {
          this.$toast.error(response.title, "Error");
        });
    },
    CalcularAcumulado() {
      this.bultos_acum = 0;
      this.kg_acum = 0;
      this.m3_acum = 0;
      this.pallets_acum = 0;
      this.valor_acum = 0;
      this.rack_acum = 0;
      this.bobina_acum = 0;
      this.tambores_acum = 0;
      this.selected.forEach((element) => {
        this.pallets_acum += element.pallets;
        this.bultos_acum += element.packages;
        this.kg_acum += element.kg;
        this.m3_acum += element.cubicMeters;
        this.valor_acum += element.declaredValue;
        this.valor_acum += element.rack;
        this.bobina_acum += element.bobina;
        this.tambores_acum += element.tambores;
      });
    },
    deleteItemRemito(item) {
      this.deleteIndexRemito = this.list.indexOf(item);
      this.deleteId = item.id;
      this.dialog_deleteRemito = true;
    },
    deleteItemConfirmRemito() {
      this.delete(this.serviceRemitos);
      this.dialog_deleteRemito = false;
      this.loadRemitosPendientes();
    },
    getState(estado) {
      return remitosService.getStatesHTML(estado);
    },
    loadLocalidadesOfProvice() {
      this.loading_locality = true;
      this.localidades = [];
      console.log("this.provincia_filter", this.provincia_filter);
      this.localidades_de_remitos_pendientes.forEach((loc) => {
        console.log("loc", loc);
        if (loc.idProvince == this.provincia_filter.id) {
          this.localidades.push(loc);
        }
      });
      this.loading_locality = false;
    },
    syncSubclientSearch() {
      //Porque sino se seteaba con el valord de (*) y no filtraba bien
      this.search = this.subcliente_filter.nameSubClient;
    },
  },
};
</script>
