<script>
// definir un objeto mixin
export default {
  data() {
    return {      
      loading: false,
    };
  },
  created: function() {
   
  },
  computed: {
   
  },
  mounted() {

  },
  methods: {
    
  },
};
</script>
