<template>
  <div>
    <Title :pages="[{ icon: 'desktop', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid v-if="list_access">
      <v-form>
        <div class="col-md-12 mb-3" v-if="loading_monitoring || rutas.totalHeader > 0">
          <div class="row">
            <div class="col p-10">
              <div class="box-monitoring-mother">
                <div class="row">
                  <div class="col box-monitoring divider ">
                    <div class="pl-3">
                      <p>{{ $t("Rutas") }}</p>
                      <div v-if="!loading_monitoring">
                        {{ rutas.totalHeader }}
                      </div>
                      <div v-if="loading_monitoring" class="skeleton_monitoring">
                        <v-skeleton-loader style="display: inline-block;margin-top: 5px;" v-if="loading_monitoring" type="chip" />
                      </div>
                    </div>
                  </div>

                  <div class="col box-monitoring divider">
                    <p>{{ $t("Completadas") }}</p>
                    <div v-if="!loading_monitoring">
                      {{ rutas.tripClosed.length }}
                    </div>
                    <div v-if="loading_monitoring" class="skeleton_monitoring">
                      <v-skeleton-loader style="display: inline-block;margin-top: 5px;" v-if="loading_monitoring" type="chip" />
                    </div>
                  </div>

                  <div class="col box-monitoring divider">
                    <p>{{ $t("En_curso") }}</p>
                    <div v-if="!loading_monitoring">
                      {{ enCurso }}
                    </div>
                    <div v-if="loading_monitoring" class="skeleton_monitoring">
                      <v-skeleton-loader style="display: inline-block;margin-top: 5px;" v-if="loading_monitoring" type="chip" />
                    </div>
                  </div>

                  <div class="col box-monitoring ">
                    <div class="pr-3">
                      <p>{{ $t("En_espera") }}</p>
                      <div v-if="!loading_monitoring">
                        {{ enEspera }}
                      </div>
                      <div v-if="loading_monitoring" class="skeleton_monitoring">
                        <v-skeleton-loader style="display: inline-block;margin-top: 5px;" v-if="loading_monitoring" type="chip" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col p-10">
              <div class="box-monitoring-mother">
                <div class="row">
                  <div class="col divider">
                    <div class="row">
                      <div class="col-md-6 box-monitoring divider">
                        <p>{{ $t("Remitos") }}</p>
                        <div v-if="!loading_monitoring">
                          {{ rutas.quantityTotalDeliveryNotes }}
                        </div>
                        <div v-if="loading_monitoring" class="skeleton_monitoring">
                          <v-skeleton-loader style="display: inline-block;margin-top: 5px;" v-if="loading_monitoring" type="chip" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div v-if="loading_monitoring" id="chart-avatar">
                          <v-skeleton-loader style="display: inline-block;margin-top: 15px;" v-if="loading_monitoring" type="avatar" />
                        </div>
                        <div id="chart" v-if="!loading_monitoring">
                          <apexchart
                            ref="Chart01"
                            style="margin: 0 auto;display: flex; justify-content: center;margin-top:8px"
                            type="donut"
                            width="110"
                            height="110"
                            :options="chart01Options"
                            :series="series01"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <table class="table table-sm mb-0 table-noborder" style="margin-top:8px">
                      <tbody>
                        <tr>
                          <td>
                            <div class="circle_entregado"></div>
                          </td>
                          <td>{{ $t("Entregados") }}</td>

                          <td v-if="!loading_monitoring">
                            <b v-if="rutas.quantityTotalDeliveryNotes != 0">
                              {{ ((rutas.quantityDeliveryNotesDelivered * 100) / rutas.quantityTotalDeliveryNotes).toFixed(2) }}
                              %</b
                            >
                            <b v-else> 0 %</b>
                          </td>
                          <td class="skeleton_percent" v-if="loading_monitoring">
                            <v-skeleton-loader style="min-width:29px;padding-right:15px" v-if="loading_monitoring" type="text" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="circle_no_entregado"></div>
                          </td>
                          <td>{{ $t("No") + $t("Entregados") }}</td>
                          <td v-if="!loading_monitoring">
                            <b v-if="rutas.quantityTotalDeliveryNotes != 0">
                              {{ ((rutas.quantityDeliveryNotesNoDelivered * 100) / rutas.quantityTotalDeliveryNotes).toFixed(2) }}
                              %
                            </b>
                            <b v-else> 0 %</b>
                          </td>
                          <td class="skeleton_percent" v-if="loading_monitoring">
                            <v-skeleton-loader style="min-width:29px;padding-right:15px" v-if="loading_monitoring" type="text" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="circle_en_camino"></div>
                          </td>
                          <td>{{ $t("En_camino") }}</td>
                          <td v-if="!loading_monitoring">
                            <b v-if="rutas.quantityTotalDeliveryNotes != 0">
                              {{ ((rutas.quantityDeliveryNotesOpen * 100) / rutas.quantityTotalDeliveryNotes).toFixed(2) }}
                              %
                            </b>
                            <b v-else> 0 %</b>
                          </td>
                          <td class="skeleton_percent" v-if="loading_monitoring">
                            <v-skeleton-loader style="min-width:29px;padding-right:15px" v-if="loading_monitoring" type="text" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading_monitoring">
          <RutaMonitoringSkeleton :loading="loading_monitoring" />
          <RutaMonitoringSkeleton :loading="loading_monitoring" />
          <RutaMonitoringSkeleton :loading="loading_monitoring" />
        </div>

        <div v-if="!loading_monitoring && rutas.totalHeader > 0" class="col-md-12 mb-3">
          <div class="row filter" style="margin:1px ; padding:10px">
            <div class="  d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
          </div>
          <div class="row filter py-0" style="margin:1px ">
            <div class="col-md-2">
              <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_filter"
                    :label="$t('Fecha')"
                    prepend-icon="mdi-calendar"
                    :disabled="numTrip_filter.length > 0 || numRemito_filter.length > 0"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date_filter" min="1950-01-01"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-4 ">
              <v-text-field data-vv-as="ruta" :label="$t('Numero_de_ruta')" name="zone" v-model="numTrip_filter"></v-text-field>
            </div>
            <div class="col-md-4">
              <v-text-field data-vv-as="remitos" :label="$t('Numero_de_remito')" name="zone" v-model="numRemito_filter"></v-text-field>
            </div>

            <div class="col-md-2 d-flex align-items-right justify-content-end pt-8">
              <v-btn color="primary" :loading="searching" dark @click="getMonitoreo()">Buscar</v-btn>
            </div>
          </div>
        </div>

        <div v-if="!loading_monitoring">
          <div class="col-md-12" v-for="ruta of rutas.tripInCourse" :key="ruta.id">
            <RutaMonitoring :ruta="ruta" />
          </div>
          <div class="col-md-12" v-for="ruta of rutas.tripClosed" :key="ruta.id">
            <RutaMonitoring :ruta="ruta" />
          </div>
        </div>

        <div v-if="rutas.length == 0 && !loading_monitoring">
          <div class="col-md-12">
            <p class="text-center">
              <i style="font-size:60px;color:#dd4b39;margin-top:40px" icon="faRoute"></i>
              <font-awesome-icon icon="road" style="font-size:60px;color:#dd4b39;margin-top:40px"> </font-awesome-icon>
            </p>
            <p class="alert-info-monitoring">
              {{ $t("Sin_rutas_activas") }}
            </p>
            <p class="fs-16 mt-0 text-center">
              {{ $t("Sin_rutas_activas_msj") }}
            </p>
          </div>
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import RutaMonitoring from "./RutaMonitoring.vue";
import { rutasService } from "@/libs/ws/rutasService";
import { monitoreoService } from "@/libs/ws/monitoreoService";
import RutaMonitoringSkeleton from "@/skeleton/RutaMonitoringSkeleton.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: { Title, RutaMonitoring, RutaMonitoringSkeleton },
  mixins: [RolMixin],
  data() {
    return {
      searching: false,
      numRemito_filter: "",
      numTrip_filter: "",
      enCurso: 0,
      enEspera: 0,
      loading_monitoring: true,
      page_title: this.$t("Monitoreo"),
      modal_title: this.$t("Monitoreo"),
      rutas: [],
      respuesta: [],
      date: "",
      dateInicio_filter: "01/06/2021",
      dateFin_filter: "30/06/2021",
      date_filter: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      chofer_filter: 0,
      ruta_filter: 0,
      onlyPendientes_filter: false,
      series01: [],
      chart01Options: {
        stroke: { show: false },
        colors: ["#5fba7d", "#fff159", "#7390fc"],
        legend: { show: false },
        dataLabels: { enabled: false },
        labels: [this.$t("Entregados"), this.$t("No_entregados"), this.$t("En_camino")],
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "70%",
              labels: { show: false },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getMonitoreo(this.formatDate(new Date().toISOString().slice(0, 10)));
    this.updateEvery();
  },
  watch: {
    rutasArray() {
      this.series01 = [this.rutas.quantityDeliveryNotesDelivered, this.rutas.quantityDeliveryNotesNoDelivered, this.rutas.quantityDeliveryNotesOpen];
    },
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  },
  computed: {
    rutasArray() {
      return this.rutas;
    },
  },
  methods: {
    updateEvery() {
      this.interval = setInterval(
        function() {
          this.getMonitoreo();
        }.bind(this),
        60000
      );
    },
    getMonitoreo() {
      this.searching = true;
      var params = this.getFilterParams();
      monitoreoService
        .filter(params)
        .then((response) => {
          if (response === "") {
            this.rutas = [];
            this.loading_monitoring = false;
            this.searching = false;
          } else {
            this.rutas = response;
            this.loading_monitoring = false;
            this.series01 = [this.rutas.quantityDeliveryNotesDelivered, this.rutas.quantityDeliveryNotesNoDelivered, this.rutas.quantityDeliveryNotesOpen];
            this.enCurso = this.getEnCurso();
            this.enEspera = this.getEnEspera();
            this.searching = false;
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
        });
    },
    getFilterParams() {
      var params = {
        clientId: 0,
        numRemito: this.numRemito_filter,
        numTrip: this.numTrip_filter == "" ? 0 : parseInt(this.numTrip_filter, 10),
        Fecha: this.formatDate(this.date_filter),
      };
      return params;
    },
    getRutasEnCurso() {
      var enCurso = 0;
      if (Array.isArray(this.rutas)) {
        this.rutas.forEach((ruta) => {
          if (ruta.delivered.length == 0) {
            return 0;
          } else {
            ruta.delivered.find(function(elemento) {
              if (!elemento.closed) {
                enCurso++;
                return enCurso;
              }
            });
          }
        });
      }

      return enCurso;
    },
    getRemitos() {
      var remitos = 0;
      if (Array.isArray(this.rutas)) {
        this.rutas.forEach((ruta) => {
          remitos = remitos + parseInt(ruta.delivered.length);
        });
      }
      return remitos;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getEnCurso() {
      var contador = 0;
      this.rutas.tripInCourse.forEach(function(elemento) {
        if (elemento.initialDate != null) {
          contador++;
        }
      });
      return contador;
    },
    getEnEspera() {
      var contador = 0;
      this.rutas.tripInCourse.forEach(function(elemento) {
        if (elemento.initialDate == null) {
          contador++;
        }
      });
      return contador;
    },
  },
};
</script>
