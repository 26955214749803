<template>
  <div>
    <Title :pages="[{ icon: 'truck', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadListCustom(service, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <ModalRemitos
        :button_name="$t('Crear') + ' ' + $t('Remitos')"
        modal_title="Remitos"
        :formBtn="formBtnRemito"
        :dialog="dialogRemitos"
        :parent="parent"
        :service="serviceRemitos"
        @updateDialog="updateDialogRemitos"
        @loadList="loadListCustom(service, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainItemRemito"
        :itemRuta="mainItem"
        @updateItem="
          (newItem) => {
            mainItemRemito = newItem;
          }
        "
      />
      <InfoRemito
        button_name="Informacion remito"
        modal_title="Informacion remito"
        :item="mainItemRemito"
        :dialog="dialogInfo"
        @updateDialog="updateDialogInfo"
        @reload="loadListCustom"
        :service="service"
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-2">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="ruta_filter.length > 0 || remito_filter.length > 0"
                      v-model="dateInicio_filter"
                      :label="$t('Fecha_desde')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateInicio_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-2">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="ruta_filter.length > 0 || remito_filter.length > 0"
                      v-model="dateFin_filter"
                      :label="$t('Fecha_hasta')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateFin_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
              <div class="col">
                <v-text-field data-vv-as="ruta" :label="$t('Ruta')" name="zone" v-model="ruta_filter"></v-text-field>
              </div>
              <div class="col">
                <v-text-field data-vv-as="remito" :label="$t('Remito')" name="remito" v-model="remito_filter"></v-text-field>
              </div>
              <div class="col pt-5">
                <v-checkbox :label="$t('Solo_pendientes')" v-model="onlyPendientes_filter"></v-checkbox>
              </div>
            </div>
            <!-- fin row1 -->

            <div class="row">
              <div class="col-md-3">
                <v-combobox v-model="chofer_filter" name="driver" item-text="nameDriver" item-value="id" :items="drivers" :label="$t('Chofer')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col-md-3">
                <v-combobox v-model="vehiculo_filter" name="vehicles" item-text="patent" item-value="id" :items="vehiculos" :label="$t('Vehiculo')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col-md-3">
                <v-combobox v-model="cliente_filter" item-text="nameClient" item-value="id" :items="clients" :label="$t('Cliente')" autocomplete="off" :clearable="true"></v-combobox>
              </div>

              <div class="col-md-3 d-flex align-items-right justify-content-end pt-8">
                <v-btn color="primary" dark @click="loadListCustom(service, 0)">{{ $t("Buscar") }}</v-btn>
              </div>

              <!-- fin row2 -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" :label="$t('Buscar')" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :single-expand="false" :loading="loading" :expanded.sync="expanded" show-expand class="elevation-1" hide-default-footer disable-pagination>
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>
                <font-awesome-icon v-if="!isExpanded" icon="chevron-down" @click="expand(!isExpanded)" class="cursor" />
                <font-awesome-icon v-else icon="chevron-up" @click="expand(!isExpanded)" class="cursor" />
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.numTrip }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ new Date(item.date).toLocaleDateString() + " " + item.date.substr(11, 8) }}
              </td>

              <td @click="expand(!isExpanded)" class="text-center ; cursor">
                {{ (item.initialCharge != null ? getHora(new Date(item.initialCharge)) : "") + " | " + (item.finishedCharge != null ? getHora(new Date(item.finishedCharge)) : "") }}
              </td>

              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.origen.dirOrigen }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.destination.dirDestination }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">"{{ item.transport.nameTransport }}"</td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.driver.nameDriver }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.vehicle != null ? item.vehicle.patent : "" }}
              </td>
              <td @click="expand(!isExpanded)" class="text-center cursor" style="width:10%">
                {{ item.ocupationState }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">${{ new Intl.NumberFormat("es-EN").format(item.totalVenta) }}</td>
              <td @click="expand(!isExpanded)" class="cursor">
                <v-progress-linear :value="getPercentCerrados(item)" :color="setColor(item)" style="height: 20px !important ; color: #666 ">
                  <template v-slot:default="{ value }">
                    <strong
                      >{{ getEstadoCantidad(item) }} /
                      {{ item.delivered.length }}
                    </strong>
                  </template>
                </v-progress-linear>
              </td>

              <td class="justify-content-center  px-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="createDeliveredNote(item)">
                      add
                    </v-icon>
                  </template>
                  <span>{{ $t("Crear") + " " + $t("Remito") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="openAddImages(item)">
                      image
                    </v-icon>
                  </template>
                  <span>Imagenes</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="purple" class="mr-2" @click="AbrirModalRemitos(item)">
                      mdi-arrow-down-bold-box-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("Descargar_hoja_ruta") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="#6e6e6e" class="mr-2" @click="ordenarRemitos(item)">
                      swap_vert
                    </v-icon>
                  </template>
                  <span>Ordenar remitos</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="update_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="delete_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2" @click="deleteItem(item), loadListCustom(service)">
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("Anular_viaje") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="headersRemitos"
                :items="item.delivered"
                :search="search"
                :loading-text="$t('cargando_espere')"
                hide-default-footer
                disable-pagination
                class="background-color: grey  lighten-3"
              >
                <template v-slot:item="props">
                  <tr>
                    <td>{{ props.item.orden }}</td>
                    <td>{{ props.item.remito }}</td>
                    <td>{{ props.item.client.nameClient }}</td>
                    <td>{{ props.item.subClient.nameSubClient }}</td>
                    <td>
                      {{ props.item.subClient.subClientAddress != null ? props.item.subClient.subClientAddress : "" }}
                    </td>
                    <td>{{ props.item.pallets }}</td>
                    <td>{{ props.item.packages }}</td>
                    <td>{{ props.item.kg }}</td>
                    <td>{{ props.item.cubicMeters }}</td>
                    <td>{{ props.item.declaredValue }}</td>
                    <td>{{ props.item.rack }}</td>
                    <td>{{ props.item.bobina }}</td>
                    <td>{{ props.item.tambores }}</td>
                    <td>${{ new Intl.NumberFormat("es-EN").format(props.item.pv) }}</td>

                    <td
                      v-html="getState(props.item.estate.id)"
                      :title="
                        props.item.estate.id == serviceRemitos.ENTREGADO || props.item.estate.id == serviceRemitos.NO_ENTREGADO
                          ? new Date(props.item.delivered).toISOString().substr(0, 10) + ' | ' + getHora(new Date(props.item.delivered))
                          : ''
                      "
                    ></td>

                    <td class="text-center px-0" style="min-width: 150px">
                      <v-tooltip bottom v-if="props.item.estate.id == serviceRemitos.EN_CAMINO || props.item.estate.id == serviceRemitos.DESPACHADO">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="green" class="mr-2" @click="openEntregarRemito(props.item, item.delivered, props.item.orden)">
                            local_shipping
                          </v-icon>
                        </template>
                        <span>Entregar remito</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="props.item.estate.id == serviceRemitos.ENTREGADO || props.item.estate.id == serviceRemitos.NO_ENTREGADO">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="openChangeStateRemito(props.item, item.id)">
                            sync
                          </v-icon>
                        </template>
                        <span>Cambiar estado</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="infoRemito(props.item)">
                            image
                          </v-icon>
                        </template>
                        <span>{{ $t("Ver_imagen") }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="update_access">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="editItemRemitos(props.item, item)">
                            edit
                          </v-icon>
                        </template>
                        <span>{{ $t("Editar") }}</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="delete_access">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" class="mr-2" @click="desasociarRemito(props.item.id, item.id)" v-show="!(props.item.estate.id == 4 || props.item.estate.id == 3)">
                            delete
                          </v-icon>
                        </template>
                        <span>{{ $t("Desasociar_remito") }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                    {{ $t("Sin_resultados") + search }}
                  </v-alert>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card>
      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          v-if="list_access"
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPageCustom(service)"
          @previous="loadPageCustom(service)"
          @input="loadPageCustom(service)"
        ></v-pagination>
      </div>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />

      <DialogDelete :dialog_delete="dialog_deleteRemito" @updateDialogDelete="dialog_deleteRemito = false" @deleteItemConfirm="deleteItemConfirmRemito" />

      <DialogDesasociate :dialog_desasociate="dialog_desasociate" @updateDialogDelete="dialog_desasociate = false" @desasociateItemConfirm="desasociateItemConfirm" />

      <ModalSeleccionarRemitos
        v-if="rutaSeleccionada != null"
        modal_title="Informacion remito"
        :dialog="dialog_seleccionar"
        formBtn="Generar Reporte"
        @updateDialog="updateDialogSeleccionar"
        :ruta="rutaSeleccionada"
        @selectedRemitos="selectedRemitos"
      />

      <ModalEntregarRemito :item="itemEntregaRemito" :dialog="dialog_entregar_remito" @close="dialog_entregar_remito = false" @entregarRemito="entregarRemito" :loading="loadingEntregarRemito" />

      <ModalChangeState :item="item_change_state" :dialog="dialog_change_state" @close="dialog_change_state = false" @action="ChangeStateRemito" :loading="loadingChangeState" />

      <ModalAviso :dialog="dialog_aviso" @close="dialog_aviso = false" />

      <ModalAddImages :item="itemAddImages" :dialog="dialog_add_image" @close="dialog_add_image = false" @uploadImages="addImagesRuta" :loading="loadingAddImage" />

      <ReporteRutas v-if="rutaSeleccionada != null" :ruta="rutaSeleccionada" :remitosSeleccionados="selected_remitos" :reportGenerate="reportGenerate" @closeReporte="reportGenerate = false" />
    </v-container>

    <v-dialog v-model="dialog_drag" width="900">
      <v-card>
        <v-card-title class="headline">
          Ordenar remitos
        </v-card-title>
        <v-card-text style="padding:30px 15px 5px 15px !important">
          <p class="fs-16 " style="font-weight:600">
            Arrastre el remito para acomodar su orden
          </p>
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th>Orden</th>
                <th>Remito n°</th>
                <th>Cliente</th>
                <th>Sub cliente</th>
                <th>Direccion</th>
                <th>Estado</th>
              </tr>
            </thead>

            <draggable v-model="remitos_drag" item-key="orden" tag="tbody" class="cursor" :move="checkMove">
              <tr v-for="(element, index) in remitos_drag" :key="element.id">
                <td style="width:10%">{{ index + 1 }}</td>
                <td style="width:10%">{{ element.remito }}</td>
                <td style="width:20%">{{ element.client.nameClient }}</td>
                <td style="width:20%">
                  {{ element.subClient.nameSubClient }}
                </td>
                <td style="width:20%">
                  {{ element.subClient.subClientAddress }}
                </td>
                <td style="width:20%" v-html="getState(element.estate.id)"></td>
              </tr>
            </draggable>
          </table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog_drag = false">
            {{ $t("Cerrar") }}
          </v-btn>
          <v-btn color="green" text @click="editarOrdenRemitos">
            {{ $t("Editar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { choferesService } from "@/libs/ws/choferesService";
import { rutasService } from "@/libs/ws/rutasService";
import { remitosService } from "@/libs/ws/remitosService";
import { clientService } from "@/libs/ws/clientService";
import { vehiculoService } from "@/libs/ws/vehiculoService";
import { imageUtils } from "@/libs/utils/imageUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogDesasociate from "@/components/DialogDesasociate";
import Modal from "./Modal.vue";
import ModalRemitos from "./ModalRemitos.vue";
import ModalAddImages from "./ModalAddImages.vue";
import ReporteRutas from "./reportesRutas.vue";
import InfoRemito from "@/views/Remitos/InfoRemito.vue";
import ModalSeleccionarRemitos from "./ModalSeleccionarRemitos.vue";
import ModalEntregarRemito from "./ModalEntregarRemito.vue";
import ModalChangeState from "./ModalChangeState.vue";

import RolMixin from "@/mixins/RolMixin.vue";
import draggable from "vuedraggable";
import ModalAviso from "./ModalAviso.vue";

export default {
  components: {
    Title,
    Modal,
    DialogDelete,
    DialogDesasociate,
    ModalRemitos,
    ReporteRutas,
    ModalAddImages,
    InfoRemito,
    ModalSeleccionarRemitos,
    ModalEntregarRemito,
    draggable,
    ModalAviso,
    ModalChangeState,
  },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      headers_drag: ["orden", "remito"],
      dialog_drag: false,
      remitos_drag: [],
      remitos_drag_aux: [],
      expanded: [],
      selected_remitos: [],
      rutaSeleccionada: null,
      dialog_seleccionar: false,
      dialogRemitos: false,
      dialog_entregar_remito: false,
      dialog_aviso: false,
      editedIndexRemito: -1,
      viewdIndexRemito: -1,
      deleteIndexRemito: -1,
      dialog_deleteRemito: false,
      dialogInfo: false,
      pdfRemitoURL: "",
      serviceRemitos: remitosService,
      reportItem: null,
      reportGenerate: false,
      parent: this,
      service: rutasService,
      page_title: this.$t("Rutas"),
      modal_title: this.$t("Ruta"),
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      chofer_filter: null,
      ruta_filter: "",
      vehiculo_filter: null,
      cliente_filter: null,
      remito_filter: "",
      onlyPendientes_filter: false,
      drivers: [],
      vehiculos: [],
      clients: [],
      subClients: [],
      loadingEntregarRemito: false,
      itemEntregaRemitoDefault: {},
      itemEntregaRemito: {
        ruta: "",
        remito: "",
        numRemito: "",
        date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        imagesUpload: [],
        state: null,
        observation: "",
      },
      mainItem: {
        id: "",
        numTrip: "",
        date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        time: "",
        origen: "",
        driver: "",
        vehicle: "",
        transport: { nameTransport: "", type: "", maxCharge: "" },
        typeCharge: "",
        destination: "",
        zone: "",
        typeTrip: "",
        totalOcupation: "",
        initialDate: "",
        initialCharge: "",
        finishedCharge: "",
        finishedTrip: "",
        ocupationState: "",
        companyCustody: null,
        basicCost: "",
        totalCost: "",
        driverLatitud: "",
        driverLongitud: "",
        idOrganizacion: "",
        peajes: "",
        observation1: "",
        observation2: "",
        isActive: true,
        delivered: [],
        aditionals: [],
        quantityModules: 0,
      },
      mainItemRemito: {
        IdTrip: "",
        Orden: "",
        IdClient: "",
        IdSubClient: "",
        remito: "",
        observation: "",
        declaredValue: 0,
        assistant: false,
        delays: false,
        stays: false,
        turns: false,
        subClient: "",
        client: "",
        additionals: [],
        images: "",
        pallets: 0,
        packages: 0,
        kg: 0,
        cubicMeters: 0,
        rack: 0,
        bobina: 0,
        tambores: 0,
      },

      defaultItem: null,
      defaultItemRemito: null,
      headers: [
        { text: "", value: "data-table-expand" },
        { text: "N°" + this.$t("Ruta"), value: "number" },
        {
          text: this.$t("Fecha"),
          align: "left",
          sortable: true,
          value: "date",
          width: "110px",
        },

        {
          text: "Inicio | Fin Carga",
          align: "left",
          sortable: true,
          value: "initialCharge" + "|" + " finishedCharge",
          width: "125px",
        },

        {
          text: this.$t("Origen"),
          align: "left",
          sortable: true,
          value: "origen.dirOrigen",
        },
        {
          text: this.$t("Tipo_viaje"),
          align: "left",
          sortable: true,
          value: "destination.dirDestination",
        },
        {
          text: "Transporte",
          align: "left",
          sortable: true,
          value: "transport.nameTransport",
        },
        {
          text: this.$t("Chofer"),
          align: "left",
          sortable: true,
          value: "driver.nameDriver",
        },
        {
          text: this.$t("Vehiculo"),
          align: "left",
          sortable: true,
          value: "vehicle.patent",
        },
        {
          text: this.$t("Ocupacion"),
          align: "center",
          sortable: true,
          value: "ocupationState",
        },
        {
          text: this.$t("Valor Total"),
          align: "left",
          sortable: true,
          value: "totalVenta",
        },
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: "delivered",
          width: "150px",
        },
        {
          text: this.$t("Accion"),
          value: "enable",
          align: "center",
          width: "170px",
        },
      ],
      list: [],
      headersRemitos: [
        {
          text: this.$t("Orden"),
          align: "left",
          sortable: true,
          value: "Orden",
          class: "grey lighten-2",
        },
        {
          text: "N° " + this.$t("Remito"),
          align: "left",
          sortable: true,
          value: "Remito",
          class: "grey lighten-2",
        },

        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "clients.nameClient",
          class: "grey lighten-2",
        },
        {
          text: "Sub Cliente",
          align: "left",
          sortable: true,
          value: "subclients.nameSubClient",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Direccion"),
          align: "left",
          sortable: true,
          value: "subclients.subClientAddress",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Pallets"),
          align: "left",
          sortable: true,
          value: "pallets",
          class: "grey lighten-2",
        },
        {
          text: "Bultos",
          align: "left",
          sortable: true,
          value: "packages",
          class: "grey lighten-2",
        },
        {
          text: "KG",
          align: "left",
          sortable: true,
          value: "kg",
          class: "grey lighten-2",
        },
        {
          text: "M3",
          align: "left",
          sortable: true,
          value: "m3",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Valor_declarado"),
          align: "left",
          sortable: true,
          value: "declaredValue",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Rack"),
          align: "left",
          sortable: true,
          value: "rack",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Bobina"),
          align: "left",
          sortable: true,
          value: "Bobina",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Tambores"),
          align: "left",
          sortable: true,
          value: "tambores",
          class: "grey lighten-2",
        },
        {
          text: this.$t("VR"),
          align: "left",
          sortable: true,
          value: "pv",
          class: "grey lighten-2",
        },
        {
          text: "Estado",
          align: "left",
          sortable: true,
          value: "state",
          class: "grey lighten-2",
        },
        {
          text: this.$t("Accion"),
          value: "enable",
          align: "center",
          class: "grey lighten-2",
        },
      ],
      dialog_desasociate: false,
      itemDesasociate: null,
      itemDesasociateRuta: null,
      loadingAddImage: false,
      dialog_add_image: false,
      itemAddImages: {
        ruta: "",
        imagesUpload: [],
        images: [],
      },
      itemAddImagesDefault: null,
      ruta_drag: null,
      dialog_change_state: false,
      item_change_state: null,
      ruta_id_change_state: null,
      loadingChangeState: false,
    };
  },
  created: function() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.closeRemitos(0);
      }
    });
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.defaultItemRemito = Object.assign({}, this.mainItemRemito);
    this.itemEntregaRemitoDefault = Object.assign({}, this.itemEntregaRemito);
    this.itemAddImagesDefault = Object.assign({}, this.itemAddImages);
    this.loadListCustom(this.service);
    this.loadChoferes();
    this.loadVehicles();
    this.loadClients();
  },
  computed: {
    formBtnRemito() {
      if (this.editedIndexRemito === -1 && this.viewdIndexRemito === -1) {
        return this.$t("Crear");
      } else if (this.viewdIndexRemito === -1) {
        return "Editar";
      }
      return "";
    },
    colorBG() {
      return {};
    },
  },
  methods: {
    ChangeStateRemito(item) {
      this.loadingChangeState = true;

      var param = {
        Id: item.id,
        IdTrip: this.ruta_id_change_state,
        CodEstate: item.state.id,
      };

      rutasService
        .ChangeStateRemito(param)
        .then((response) => {
          this.dialog_change_state = false;
          this.loadingChangeState = false;
          this.loadingEntregarRemito = false;
          this.loadPageCustom(this.service);
        })
        .catch((e) => {
          this.loadingChangeState = false;
        });
    },
    openEntregarRemito(item, delivered, orden) {
      let index = orden - 2;

      const beforeRemito = delivered[index];

      if (index < 0) {
        this.itemEntregaRemito.ruta = item.idTrip;
        this.itemEntregaRemito.remito = item.id;
        this.itemEntregaRemito.numRemito = item.remito;
        this.dialog_entregar_remito = true;
      } else if (beforeRemito.estate.id === this.serviceRemitos.ENTREGADO || beforeRemito.estate.id === this.serviceRemitos.NO_ENTREGADO) {
        this.itemEntregaRemito.ruta = item.idTrip;
        this.itemEntregaRemito.remito = item.id;
        this.itemEntregaRemito.numRemito = item.remito;
        this.dialog_entregar_remito = true;
      } else {
        this.dialog_aviso = true;
      }
    },
    openChangeStateRemito(item, ruta_id) {
      this.item_change_state = item;
      this.ruta_id_change_state = ruta_id;
      this.dialog_change_state = true;
    },
    async entregarRemito(param) {
      this.loadingEntregarRemito = true;

      param.date = this.formatDate(param.date);

      var images = [];
      if (param.imagesUpload.length > 0) {
        for (const element of param.imagesUpload) {
          var obj = { image: null };
          obj.image = await imageUtils.getBase64(element);
          images.push(obj);
        }
      }

      param.images = images;

      remitosService
        .entregarRemito(param)
        .then((response) => {
          console.log(response);
          this.dialog_entregar_remito = false;
          this.itemEntregaRemito = Object.assign({}, this.itemEntregaRemitoDefault);
          this.loadingEntregarRemito = false;
          this.loadPageCustom(this.service);
        })
        .catch((e) => {
          this.loadingEntregarRemito = false;
        });
    },
    openAddImages(item) {
      this.itemAddImages.ruta = item.id;
      this.itemAddImages.images = item.images;
      this.dialog_add_image = true;
    },
    async addImagesRuta(param) {
      this.loadingAddImage = true;

      param.ruta = this.itemAddImages.ruta;

      var images = [];
      if (param.imagesUpload.length > 0) {
        for (const element of param.imagesUpload) {
          var obj = { image: null };
          obj.image = await imageUtils.getBase64(element);
          images.push(obj);
        }
      }

      param.images = images;

      rutasService
        .addImages(param)
        .then((response) => {
          this.$toast.success("Las imagenes del viaje se guardaron correctamente.", "OK");
          this.dialog_add_image = false;
          this.itemAddImages = Object.assign({}, this.itemAddImagesDefault);
          this.loadingAddImage = false;
          this.loadPageCustom(this.service);
        })
        .catch((e) => {
          this.loadingAddImage = false;
        });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadListCustom(this.service);
    },
    deleteItemConfirmRemito() {
      this.delete(this.serviceRemitos);
      this.dialog_deleteRemito = false;
      this.loadListCustom(this.service);
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadVehicles() {
      vehiculoService
        .getAll()
        .then((response) => {
          this.vehiculos = response.vehicles;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadChoferes() {
      choferesService
        .getAll()
        .then((response) => {
          this.drivers = response.drivers;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    updateDialogInfo(value) {
      if (!value) {
        this.dialogInfo = false;
        return;
      }
    },
    updateDialogSeleccionar(value) {
      if (!value) {
        this.dialog_seleccionar = false;
        return;
      }
    },

    updateDialogRemitos(value, tipo = 0) {
      if (!value) {
        this.closeRemitos(tipo);
        return;
      }

      //this.dialogRemitos = value;
    },
    closeRemitos(tipo) {
      if (tipo == 0) {
        this.mainItemRemito = Object.assign({}, this.defaultItemRemito);
        this.dialogRemitos = false;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.close();
      } else if (tipo == 1) {
        var orden = 0;
        orden = this.mainItemRemito.Orden + 1;
        var additionals = this.mainItem.aditionals;
        this.mainItemRemito = Object.assign({}, this.defaultItemRemito);
        this.mainItemRemito.Orden = orden;
        this.mainItemRemito.additionals = additionals;
        this.mainItemRemito.additionals.forEach((element) => {
          element.checkbox = false;
        });
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.dialogRemitos = true;
      } else {
        this.mainItemRemito.Orden = this.mainItemRemito.Orden + 1;
        var additionals2 = this.mainItem.aditionals;
        this.mainItemRemito.additionals = additionals2;
        this.editedIndexRemito = -1;
        this.viewdIndexRemito = -1;
        this.dialogRemitos = true;
      }
    },
    createDeliveredNote(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialogRemitos = true;
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
    viewItemRemitos(item) {
      this.viewdIndexRemito = 1;
      this.mainItemRemito = Object.assign({}, item);
      this.dialogRemitos = true;
    },
    editItemRemitos(item, ruta) {
      this.errors.clear();
      this.editedIndexRemito = 1;
      this.mainItemRemito = Object.assign({}, item);
      this.dialogRemitos = true;
      this.mainItem = Object.assign({}, ruta);
    },
    loadListCustom(service, startIndex = 0) {
      this.loading = true;

      //Controls
      var param = {
        dateInicio_filter: this.formatDate(this.dateInicio_filter),
        dateFin_filter: this.formatDate(this.dateFin_filter),
        chofer_filter: this.chofer_filter,
        ruta_filter: this.ruta_filter,
        remito_filter: this.remito_filter,
        onlyPendiente: this.onlyPendientes_filter,
        vehiculo_filter: this.vehiculo_filter,
        cliente_filter: this.cliente_filter,
      };
      //
      service
        .filter(param, startIndex, this.$store.state.config.items_per_page)
        .then((response) => {
          if (startIndex == 0) {
            this.current_page = 1;
          }
          console.log(response.list);
          this.list = response.list;
          this.cant_pages = Math.ceil(response.quantity / this.$store.state.config.items_per_page);
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadPageCustom(service, startIndex = (this.current_page - 1) * this.$store.state.config.items_per_page) {
      this.loadListCustom(service, startIndex);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    getPercentCerrados(item) {
      var contador = this.getEstadoCantidad(item);
      if (contador == 0) {
        return 0;
      }
      return ((contador * 100) / item.delivered.length).toFixed(2);
    },
    getEstadoCantidad(item) {
      var contador = 0;
      if (item.delivered.length == 0) {
        return 0;
      }
      item.delivered.forEach(function(elemento) {
        if (elemento.estate.id == remitosService.ENTREGADO || elemento.estate.id == remitosService.NO_ENTREGADO) {
          contador++;
        }
      });
      return contador;
    },
    getPercentEntregados(item) {
      var contador = 0;
      if (item.delivered.length == 0) {
        return 0;
      }
      item.delivered.forEach(function(elemento) {
        if (elemento.estate.id == remitosService.ENTREGADO) {
          contador++;
        }
      });
      return ((contador * 100) / item.delivered.length).toFixed(2);
    },

    setColor(item) {
      var total_percent_cerrados = this.getPercentCerrados(item);
      var percent_entregados = this.getPercentEntregados(item);

      if (total_percent_cerrados == 0 && item.initialDate == null) {
        return "#ff5252";
      } else if (total_percent_cerrados == 0 && item.initialDate != null) {
        return "blue-grey";
      } else if (total_percent_cerrados > 0 && total_percent_cerrados < 100) {
        return "#7390fc";
      } else if (total_percent_cerrados == 100 && total_percent_cerrados != percent_entregados) {
        return "#fff159";
      } else if (total_percent_cerrados == 100 && total_percent_cerrados == percent_entregados) {
        return "#5fba7d";
      }
    },
    deleteItemRemito(item) {
      this.deleteIndexRemito = this.list.indexOf(item);
      this.deleteId = item.id;
      this.dialog_deleteRemito = true;
    },

    moverRemito(item, direccion) {
      this.mainItemRemito = Object.assign({}, item);
      if (item.orden == 1 && direccion == 1) {
        this.$toast.error("El orden mínimo de remito es 1", "Error");
      } else {
        remitosService.cambiarOrden(this.mainItemRemito.id, direccion);
      }
      this.loadListCustom(this.service);
    },
    infoRemito(item) {
      this.errors.clear();
      this.mainItemRemito = Object.assign({}, item);
      this.dialogInfo = true;
    },
    AbrirModalRemitos(item) {
      this.rutaSeleccionada = item;
      this.dialog_seleccionar = true;
    },
    selectedRemitos(remitos) {
      this.selected_remitos = remitos;
      this.reportGenerate = true;
    },
    getState(estado) {
      return remitosService.getStatesHTML(estado);
    },
    ordenarRemitos(ruta) {
      this.ruta_drag = ruta.id;
      this.remitos_drag = ruta.delivered;
      this.remitos_drag_aux = ruta.delivered;
      this.dialog_drag = true;
    },
    editarOrdenRemitos() {
      var flag = true;
      var array = [];
      var current = this;
      this.remitos_drag.forEach((element, index) => {
        var remito = { IdDelivered: 0, Order: 0, IdTrip: current.ruta_drag };
        remito.IdDelivered = element.id;
        remito.Order = index + 1;
        array.push(remito);
      });

      this.remitos_drag_aux.forEach((element, index) => {
        if (element.estate.id == remitosService.ENTREGADO || element.estate.id == remitosService.NO_ENTREGADO) {
          if (this.remitos_drag.indexOf(element) != index) {
            flag = false;
          }
        }
      });
      if (flag) {
        this.service
          .OrderDelivered(array)
          .then((response) => {
            this.dialog_drag = false;
            this.loadListCustom(this.service);
            this.$toast.success("Orden actualizada correctamente", "OK");
          })
          .catch((e) => {
            console.log("service.filter error");
            console.log(e);
            this.$toast.error("Ocurrió un error", "Error");
          });
      } else {
        this.$toast.error("Los remitos que se encuentran en ENTREGADOS O NO_ENTREGADOS no pueden cambiar de orden", "Error");
      }
    },
    desasociarRemito(remito, ruta_id) {
      this.itemDesasociate = remito;
      this.itemDesasociateRuta = ruta_id;
      this.dialog_desasociate = true;
    },
    desasociateItemConfirm() {
      var param = { Id: this.itemDesasociate, IdTrip: this.itemDesasociateRuta };
      remitosService
        .DesasociateDelivered(param)
        .then((response) => {
          this.dialog_desasociate = false;
          this.itemDesasociate = null;
          this.itemDesasociateRuta = null;
          this.$toast.success("Remito desasociado correctamente", "OK");
          this.loadListCustom(this.service);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Ocurrió un error", "Error");
        });
    },
    checkMove(evt) {
      return evt.draggedContext.element.estate.id !== remitosService.ENTREGADO && evt.draggedContext.element.estate.id !== remitosService.NO_ENTREGADO;
    },
  },
};
</script>
